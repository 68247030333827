import { useCallback } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { GuideStep, Substep } from "../../features/guide/guide.steps";
import { IconTypes } from "../../components/icons.types";
import { useQuickGuide } from "../../features/guide/useQuickGuide";
import HelpButton from "../../components/buttons/HelpButton";
import { getFeature } from "../../features/guide/features";

export interface GuideContext {
  hasCatalog: boolean;
  hasProducts: boolean;
  isInCatalogDesigner: boolean;
  currentCatalogId?: string;
  navigateToCatalogDesigner: (start: string) => void;
  openProductEditor: (key: string) => void;
}

interface ContextAwareStepProps {
  step: GuideStep;
  substep: Substep;
  context: GuideContext;
  onAction: (key: string) => void;
  isCompleted?: boolean;
}

const ContextAwareStep: React.FC<ContextAwareStepProps> = ({
  substep,
  context,
  onAction,
  isCompleted,
}) => {
  const isActionable =
    (!substep.requiresCatalog && !substep.requiresProducts) ||
    (substep.requiresCatalog && context.hasCatalog) ||
    (substep.requiresProducts && context.hasProducts);

  const feature = getFeature(substep.feature);

  const getGuidanceMessage = useCallback(() => {
    if (substep.requiresCatalog && !context.hasCatalog) {
      return "Create a catalog first to use this feature";
    }
    if (substep.requiresCatalog && !context.isInCatalogDesigner) {
      return "Open catalog designer to use this feature";
    }
    if (substep.requiresProducts && !context.hasProducts) {
      return "Add products first to use this feature";
    }
    return substep.description || feature?.description || "";
  }, [
    context.hasCatalog,
    context.hasProducts,
    context.isInCatalogDesigner,
    feature,
    substep,
  ]);

  const handleAction = useCallback(
    (e: React.MouseEvent) => {
      if (!isActionable) {
        e.preventDefault();
        return;
      }

      if (substep.requiresCatalog) {
        context.navigateToCatalogDesigner(substep.key);
        return;
      }
      if (substep.requiresProducts) {
        context.openProductEditor(substep.key);
        return;
      }

      onAction(substep.key);
    },
    [
      context,
      isActionable,
      onAction,
      substep.key,
      substep.requiresCatalog,
      substep.requiresProducts,
    ]
  );

  const SubstepIcon = substep.icon || feature?.icon || IconTypes.CheckCircle;

  const path = substep.path || feature?.path;

  return (
    <Tooltip
      title={!isActionable ? getGuidanceMessage() : ""}
      placement="top"
      arrow
    >
      <Card
        variant="outlined"
        sx={{
          opacity: isActionable ? 1 : 0.7,
          transition: "all 0.2s",
          "&:hover": {
            bgcolor: isActionable ? "action.hover" : "inherit",
          },
          borderColor: isCompleted ? "success.main" : undefined,
        }}
      >
        <CardActionArea
          component={path ? RouterLink : "div"}
          to={path}
          onClick={handleAction}
          sx={{ cursor: isActionable ? "pointer" : "default" }}
        >
          <CardContent sx={{ "&:last-child": { pb: 2 }, py: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <SubstepIcon
                fontSize="small"
                color={isActionable ? "action" : "disabled"}
              />
              <Box sx={{ flex: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: isActionable ? "text.primary" : "text.disabled",
                    }}
                  >
                    {substep.label || feature?.title}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      ml: "auto",
                      alignItems: "center",
                    }}
                  >
                    {isCompleted && (
                      <IconTypes.CheckCircle fontSize="small" color="success" />
                    )}
                    <HelpButton
                      helpUrl={feature?.helpUrl}
                      title="Learn more"
                      size="small"
                    />
                  </Box>
                </Box>
                <Typography
                  variant="body2"
                  color={isActionable ? "text.secondary" : "text.disabled"}
                >
                  {getGuidanceMessage()}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Tooltip>
  );
};

interface ContextAwareGuideProps {
  steps: GuideStep[];
  context: GuideContext;
  onStepAction: (key: string) => void;
}

export const ContextAwareGuide: React.FC<ContextAwareGuideProps> = ({
  steps,
  context,
  onStepAction,
}) => {
  const { checkSubstepCompletion } = useQuickGuide();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {steps.map((step) => (
        <Box
          key={step.key}
          sx={{ display: "flex", flexDirection: "column", gap: 1 }}
        >
          {step.substeps?.map((substep) => (
            <ContextAwareStep
              key={substep.key}
              step={step}
              substep={substep}
              context={context}
              onAction={onStepAction}
              isCompleted={checkSubstepCompletion(substep)}
            />
          ))}
        </Box>
      ))}
    </Box>
  );
};
