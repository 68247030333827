// journey.state.ts
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { sessionAtom } from "../accounts/account.state";
import apiClient from "../../services/apiClient";
import { devLog } from "../../services/util";
import { JourneyProgress } from "./journey";

export type DisplayMode = "expanded" | "collapsed" | "hidden";

export type JourneyProgressSet = Record<string, Set<string>>;

export interface GuideState {
  displayMode: DisplayMode;
  userMode: DisplayMode;
  expandedStep?: string;
  hideCompleted?: boolean;
}

interface JourneyState {
  progress: JourneyProgressSet;
  loading: boolean;
  loaded?: boolean;
  error: string | null;
}

const initialJourneyState: JourneyState = {
  progress: {},
  loading: false,
  error: null,
};

const initialGuideState: GuideState = {
  displayMode: "expanded",
  userMode: "expanded",
  hideCompleted: true,
};

const getInitialState = (): GuideState => {
  try {
    const stored = localStorage.getItem("guide_state");
    return stored ? JSON.parse(stored) : initialGuideState;
  } catch {
    return initialGuideState;
  }
};

export const guideStateAtom = atomWithStorage<GuideState>(
  "guide_state",
  getInitialState()
);

export const journeyStateAtom = atom<JourneyState>(initialJourneyState);

export const loadJourneyProgressAtom = atom(null, async (get, set) => {
  const session = await get(sessionAtom);
  if (!session?.account) {
    set(journeyStateAtom, initialJourneyState);
    return;
  }

  set(journeyStateAtom, (state) => ({ ...state, loading: true, error: null }));

  try {
    const response = await apiClient.get<JourneyProgress[]>("account/progress");
    const progress = response.data.reduce((acc, item) => {
      if (!acc[item.journeyType]) {
        acc[item.journeyType] = new Set();
      }
      acc[item.journeyType].add(item.stepKey);
      return acc;
    }, {} as JourneyProgressSet);

    const newState = { progress, loading: false, error: null, loaded: true };
    devLog("Loading progress", newState);
    set(journeyStateAtom, newState);
  } catch (error) {
    set(journeyStateAtom, {
      progress: {},
      loading: false,
      error: error instanceof Error ? error.message : "Unknown error",
    });
  }
});

export const journeyProgressAtom = atom(
  (get) => get(journeyStateAtom).progress
);

export const addJourneyProgressAtom = atom(
  null,
  (get, set, journey: JourneyProgress) => {
    const state = get(journeyStateAtom);
    const newProgress = { ...state.progress };

    if (!newProgress[journey.journeyType]) {
      newProgress[journey.journeyType] = new Set();
    }
    newProgress[journey.journeyType].add(journey.stepKey);

    const newState = { ...state, progress: newProgress };
    devLog("Adding progress", newState);
    set(journeyStateAtom, newState);
  }
);
