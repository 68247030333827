import { Box, Button } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridActionsCellItem,
  GridPreProcessEditCellProps,
  GridRowOrderChangeParams,
  DataGridPro,
  GridToolbarProps,
} from "@mui/x-data-grid-pro";
import { useAtom } from "jotai";
import { useDialogs } from "../../../contexts/useDialogs";
import { dataPatterns, swapEntries } from "../../../features/data/data-types";
import { PRODUCTS_FOLDER } from "../../../features/images/image";
import { Product, ProductVariant } from "../../../features/products/product";
import { productDbAtom } from "../../../features/products/product.state";
import { ImageModalType } from "../../images/modals/ImageModal";
import { ImageCell } from "./grid-components";
import { IconTypes } from "../../../components/icons.types";
import { useCallback, useMemo } from "react";

interface CustomToolbarProps extends GridToolbarProps {
  onAdd: () => void;
}

export interface VariantGridProps {
  product: Product;
  onUpdate: (newRow, oldRow) => void;
  onDelete: (row) => void;
  onAdd?: () => void;
  onReorder?: (variants: ProductVariant[]) => void;
  toolbar?: boolean;
  reorder?: boolean;
  isReadOnly: boolean;
}

export const VariantToolbar = ({ onAdd }: { onAdd: () => void }) => (
  <Box>
    <Button
      color="primary"
      startIcon={<IconTypes.Add />}
      onClick={onAdd}
      variant="text"
      size="small"
    >
      Add variant
    </Button>
  </Box>
);

export const VariantGrid = ({
  product,
  onUpdate,
  onDelete,
  onAdd,
  onReorder,
  toolbar,
  reorder,
  isReadOnly,
}: VariantGridProps) => {
  const [productDb] = useAtom(productDbAtom);
  const { openImageModal } = useDialogs();

  const openImageView = useCallback(
    (type: ImageModalType, row) => {
      openImageModal(
        {
          path: row.image,
          onSelect: (image) => onUpdate({ ...row, image }, row),
          canChange: !isReadOnly,
          canSelectFolder: true,
          folder: PRODUCTS_FOLDER,
        },
        type
      );
    },
    [isReadOnly, onUpdate, openImageModal]
  );

  const variantColumns = useMemo<{ [key: string]: GridColDef[] }>(
    () => ({}),
    []
  );

  const commonVariantColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "position",
        headerName: "#",
        width: 35,
        //renderCell: ({ row }: GridRenderCellParams) => row.position + 1,
      },
      {
        field: "image",
        headerName: "Image",
        align: "center",
        maxWidth: 60,
        renderCell: ({ row }: GridRenderCellParams) => {
          return (
            <ImageCell
              className="variant-image"
              thumb={row.thumb}
              onClick={(type) => openImageView(type, row)}
            />
          );
        },
      },
      {
        field: "actions",
        type: "actions",
        width: 35,
        getActions: (params) =>
          isReadOnly
            ? []
            : [
                <GridActionsCellItem
                  icon={<IconTypes.DeleteAction />}
                  label="Delete variant"
                  key="1"
                  onClick={() => {
                    onDelete(params.row);
                  }}
                />,
              ],
      },
      { field: "sku", headerName: "SKU" },
      {
        field: "price",
        headerName: "Price",
        align: "right",
      },
      {
        field: "quantity",
        headerName: "Quantity",
        align: "right",
        type: "number",
      },
      { field: "description", headerName: "Description", flex: 1 },
      { field: "barcode", headerName: "Barcode" },
    ],
    [isReadOnly, onDelete, openImageView]
  );

  const getVariantColumns = useCallback(
    (categoryId: string) => {
      const v = variantColumns[categoryId];
      if (v) return v;

      const category = productDb.categoryMap.get(categoryId);
      if (!category) return [];

      const optionColumns: GridColDef[] = category.options.map((o) => ({
        field: o.name,
        valueGetter: (_value, row) => {
          const p = row.$product as Product;
          const v = p?.variants?.find((v) => v.id === row.id);
          return (
            v?.options.find((vo) => vo.optionId === o.optionId)?.value ?? ""
          );
        },
      }));
      const vc = commonVariantColumns.map((c) => ({
        ...c,
        valueGetter: (value) => value ?? "",
      }));
      const cols = [...vc.slice(0, 3), ...optionColumns, ...vc.slice(3)].map(
        (c) => ({
          ...c,
          editable: !isReadOnly,
          preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
            if (c.field === "price" && params.props.value) {
              const error = !dataPatterns.money.test(
                params.props.value.toString()
              );
              return {
                ...params.props,
                error,
              };
            }
            return params.props;
          },
        })
      );
      variantColumns[category.group.id] = cols;
      return cols;
    },
    [commonVariantColumns, isReadOnly, productDb.categoryMap, variantColumns]
  );

  const handleOrderChange = useCallback(
    (params: GridRowOrderChangeParams) => {
      if (isReadOnly) return;
      const variants = swapEntries(
        product.variants,
        params.oldIndex,
        params.targetIndex
      );
      variants.forEach((v, i) => (v.position = i + 1));
      onReorder?.(variants);
    },
    [isReadOnly, onReorder, product.variants]
  );

  if (!product.variants?.length && !isReadOnly) {
    return <VariantToolbar onAdd={onAdd} />;
  }
  if (product.variants?.length > 0) {
    const vrows = product.variants.map((v) => ({
      $product: product,
      ...v,
      ...Object.fromEntries(v.options.map((o) => [o.name, o.value ?? ""])),
    }));

    const processRowUpdateError = (error: Error) => {
      console.error(error);
    };

    return (
      <DataGridPro
        density="compact"
        columns={getVariantColumns(product.categoryId)}
        rows={vrows}
        sx={{
          flex: 1,
          borderTop: 0,
          borderLeft: 0,
          borderRight: 0,
          borderRadius: 0,
          "& .MuiDataGrid-row": {
            "& .MuiDataGrid-actionsCell": {
              display: "none",
            },
          },
          "& .MuiDataGrid-row:hover": {
            "& .MuiDataGrid-actionsCell": {
              display: "inline-flex",
            },
          },
          "& .variant-image": {
            cursor: "pointer",
            pt: "5px",
            "& img": {
              maxWidth: "40px",
              maxHeight: "32px",
            },
          },
        }}
        hideFooter
        processRowUpdate={(newRow, oldRow) => onUpdate(newRow, oldRow)}
        onProcessRowUpdateError={processRowUpdateError}
        rowReordering={reorder}
        onRowOrderChange={handleOrderChange}
        slots={{
          toolbar: toolbar && !isReadOnly ? VariantToolbar : null,
        }}
        slotProps={{
          toolbar: { onAdd } as CustomToolbarProps,
        }}
      />
    );
  }
  return null;
};
