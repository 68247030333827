import {
  HubConnectionBuilder,
  HubConnection,
  LogLevel,
} from "@microsoft/signalr";
import { getAuthToken } from "../features/accounts/auth.service";
import { devLog } from "./util";
import { JourneyProgress } from "../features/guide/journey";

export const hubUrl = import.meta.env.VITE_API_URL + "/accountHub";

class AccountHubService {
  private connection: HubConnection;
  private connectionPromise: Promise<void> | null = null;
  private journeyCallbacks: ((data: {
    journeyType: string;
    stepKey: string;
  }) => void)[] = [];

  constructor() {
    this.connection = this.createConnection();
    this.connection.on("journeyUpdated", (data) => {
      this.journeyCallbacks.forEach((callback) => callback(data));
    });
  }

  private createConnection(): HubConnection {
    return new HubConnectionBuilder()
      .withUrl(hubUrl, {
        accessTokenFactory: () => getAuthToken() || "",
      })
      .withAutomaticReconnect()
      .configureLogging(
        process.env.NODE_ENV === "development" ? LogLevel.Debug : LogLevel.Error
      )
      .build();
  }

  async connect() {
    if (!this.connectionPromise) {
      this.connectionPromise = this.connection
        .start()
        .then(() => {
          devLog("Connected to account hub");
        })
        .catch((err) => {
          console.error("Failed to connect:", err);
          this.connectionPromise = null;
          throw err;
        });
    }
    return this.connectionPromise;
  }

  onJourneyUpdated(callback: (data: JourneyProgress) => void) {
    this.journeyCallbacks.push(callback);
    return () => {
      this.journeyCallbacks = this.journeyCallbacks.filter(
        (cb) => cb !== callback
      );
    };
  }

  async disconnect() {
    try {
      if (this.connection) {
        await this.connection.stop();
        this.connectionPromise = null;
      }
    } catch (err) {
      console.error("Error disconnecting:", err);
    }
  }

  async sendEchoTest(message: string) {
    try {
      if (this.connection.state === "Connected") {
        await this.connection.invoke("EchoTest", message);
      } else {
        console.debug("Not connected. Current state:", this.connection.state);
      }
    } catch (err) {
      console.error("Error sending echo:", err);
    }
  }

  onEchoResponse(callback: (message: string) => void) {
    this.connection.on("EchoResponse", (message) => {
      console.debug("Echo response received:", message);
      callback(message);
    });
  }
}

export const accountHub = new AccountHubService();
