import {
  ApplySchemaAttributes,
  command,
  extension,
  ExtensionPriority,
  ExtensionTag,
  isString,
  joinStyles,
  Mark,
  MarkExtension,
  MarkExtensionSpec,
  MarkSpecOverride,
  omitExtraAttributes,
  PrimitiveSelection,
  Static,
} from "remirror";
import type { CommandFunction } from "remirror";

const setTextBgColorOptions: Remirror.CommandDecoratorOptions = {
  icon: "markPenLine",
  description: () => "Set the text background color for the selected text.",
  label: () => "Text background color",
};

//const TEXT_BG_COLOR_ATTRIBUTE = "data-text-bgcolor-mark";

@extension<TextBgColorOptions>({
  defaultOptions: {
    defaultBgColor: "",
  },
  staticKeys: ["defaultBgColor"],
})
export class TextBgColorExtension extends MarkExtension<TextBgColorOptions> {
  get name() {
    return "textBgColor" as const;
  }

  createTags() {
    return [ExtensionTag.FormattingMark, ExtensionTag.FontStyle];
  }

  createMarkSpec(
    extra: ApplySchemaAttributes,
    override: MarkSpecOverride
  ): MarkExtensionSpec {
    return {
      ...override,
      attrs: {
        ...extra.defaults(),
        bgColor: { default: this.options.defaultBgColor },
      },
      parseDOM: [
        // {
        //   tag: `span[${TEXT_BG_COLOR_ATTRIBUTE}]`,
        //   getAttrs: (dom) => {
        //     if (!isElementDomNode(dom)) {
        //       return null;
        //     }

        //     const bgColor = dom.getAttribute(TEXT_BG_COLOR_ATTRIBUTE);

        //     if (!bgColor) {
        //       return null;
        //     }

        //     return { ...extra.parse(dom), bgColor };
        //   },
        // },
        // {
        //   tag: `span[${TEXT_BG_COLOR_ATTRIBUTE}]`,
        //   getAttrs: (dom) => {
        //     if (!isElementDomNode(dom)) {
        //       return null;
        //     }

        //     const bgColor = dom.getAttribute(TEXT_BG_COLOR_ATTRIBUTE);

        //     if (!bgColor) {
        //       return null;
        //     }

        //     return { ...extra.parse(dom), bgColor };
        //   },
        // },
        {
          // Get the color from the css style property. This is useful for pasted content.
          style: "background-color",
          priority: ExtensionPriority.Low,
          getAttrs: (bgColor) => {
            if (!isString(bgColor)) {
              return null;
            }

            return { bgColor };
          },
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM: (mark: Mark) => {
        const { bgColor, ...other } =
          omitExtraAttributes<TextBgColorAttributes>(mark.attrs, extra);
        const extraAttrs = extra.dom(mark);
        let style = extraAttrs.style;

        if (bgColor) {
          style = joinStyles({ backgroundColor: bgColor }, style);
        }

        return [
          "span",
          {
            ...other,
            ...extraAttrs,
            style,
            //[TEXT_BG_COLOR_ATTRIBUTE]: bgColor,
          },
          0,
        ];
      },
    };
  }

  @command(setTextBgColorOptions)
  setTextBgColor(
    bgColor: string,
    options?: BgColorCommandOptions
  ): CommandFunction {
    return this.store.commands.applyMark.original(
      this.type,
      { bgColor },
      options?.selection
    );
  }

  @command()
  removeTextBgColor(options?: BgColorCommandOptions): CommandFunction {
    return this.store.commands.removeMark.original({
      type: this.type,
      ...options,
      expand: true,
    });
  }
}

export interface TextBgColorOptions {
  defaultBgColor?: Static<string>;
}

export interface TextBgColorAttributes {
  bgColor?: string;
}

interface BgColorCommandOptions {
  selection?: PrimitiveSelection;
}

declare global {
  namespace Remirror {
    interface AllExtensions {
      textBgColor: TextBgColorExtension;
    }
  }
}
