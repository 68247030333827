//https://github.com/remirror/remirror/blob/main/packages/remirror__extension-link/src/link-extension.ts
import {
  NodeExtension,
  NodeExtensionSpec,
  ApplySchemaAttributes,
  command,
} from "@remirror/core";
import { Fragment } from "@remirror/pm/model";
import { TextSelection } from "@remirror/pm/state";
import {
  CreateExtensionPlugin,
  EditorState,
  ExtensionTag,
  findChildren,
  findParentNodeOfType,
  isElementDomNode,
  omitExtraAttributes,
} from "remirror";
import type { CommandFunction } from "remirror";

const urlRegex = /\bhttps?:\/\/[a-zA-Z0-9-.]+\.[a-z]{2,}(\/\S*)?\b/gi;

interface StyledLinkAttributes {
  href: string;
  target?: string;
}

export const styledLinkName = "styledLink" as const;

export class StyledLinkExtension extends NodeExtension {
  get name() {
    return styledLinkName;
  }

  createTags() {
    return [ExtensionTag.Link];
  }

  createNodeSpec(extra: ApplySchemaAttributes): NodeExtensionSpec {
    return {
      content: "inline*",
      inline: true,
      marks: "_",
      group: "inline",
      draggable: false,
      atom: false,
      attrs: {
        href: {},
        ...extra.defaults(),
        target: { default: "_blank" },
      },

      parseDOM: [
        {
          tag: "a[href]",
          getAttrs: (dom) => {
            if (!isElementDomNode(dom)) {
              return false;
            }
            return {
              ...extra.parse(dom),
              href: dom.getAttribute("href"),
              target: dom.getAttribute("target"),
            };
          },
        },
      ],
      toDOM: (node) => {
        const { href, target, ...rest } = omitExtraAttributes(
          node.attrs,
          extra
        );
        const attrs = {
          ...extra.dom(node),
          ...rest,
          href,
          target,
          rel: "noopener noreferrer nofollow",
        };

        return ["a", attrs, 0];
      },
    };
  }

  createPlugin(): CreateExtensionPlugin {
    return {
      appendTransaction: (transactions, _prevState, state: EditorState) => {
        const tr = state.tr;
        let modified = false;

        transactions.forEach((transaction) => {
          if (!transaction.docChanged) return;

          state.doc.descendants((node, pos) => {
            if (!node.isText) return;

            urlRegex.lastIndex = 0; // Reset the regex state
            let match;

            while ((match = urlRegex.exec(node.text)) !== null) {
              const start = pos + match.index;
              const end = start + match[0].length;

              let alreadyStyled = false;

              // Traverse the document to find the parent node containing this slice.
              state.doc.nodesBetween(start, end, (n) => {
                if (n.type.name === styledLinkName) {
                  alreadyStyled = true;
                  return false; // Stop traversing
                }
                return true;
              });

              if (!alreadyStyled) {
                const slice = state.doc.slice(start, end);
                tr.replaceWith(
                  start,
                  end,
                  state.schema.nodes.styledLink.create(
                    { href: match[0] },
                    slice.content
                  )
                );
                modified = true;
              }
            }
          });
        });

        if (modified) return tr;
      },
    };
  }

  @command()
  updateStyledLink(
    attributes: StyledLinkAttributes,
    text: string
  ): CommandFunction {
    return ({ tr, state, dispatch }) => {
      const { from } = tr.selection;

      const node = tr.doc.nodeAt(from);
      if (!node || node.type.name !== styledLinkName) {
        return false;
      }

      const newContent = [];
      node.content.forEach((child) => {
        if (child.isText) {
          newContent.push(state.schema.text(text, child.marks));
        } else {
          newContent.push(child);
        }
      });

      const newNode = node.type.create(
        { ...node.attrs, ...attributes },
        Fragment.from(newContent)
      );

      tr.replaceWith(from, from + node.nodeSize, newNode);

      if (dispatch) {
        dispatch(tr);
      }
      return true;
    };
  }

  @command()
  removeStyledLink(range?: { from: number; to: number }): CommandFunction {
    return ({ tr, dispatch }) => {
      const { from, to } = range ?? tr.selection;

      // Find the `StyledLink` node within the given range.
      const node = tr.doc.nodeAt(from);
      if (!node || node.type.name !== styledLinkName) {
        return false;
      }
      const content = node.content;
      tr.replaceWith(from, to, content);
      if (dispatch) {
        dispatch(tr);
      }

      return true;
    };
  }

  @command()
  selectStyledLink(): CommandFunction {
    return ({ tr, dispatch }) => {
      let { from, to } = tr.selection;

      // Search for a link node that contains the cursor.
      const parent = findParentNodeOfType({
        types: this.type,
        selection: tr.selection,
      });

      if (parent) {
        from = parent.pos;
        to = parent.pos + parent.node.nodeSize;
      } else {
        const { doc } = tr;
        const result = findChildren({
          node: doc.cut(from, to),
          predicate: (node) => node.node.type.name === styledLinkName,
        });

        if (result.length) {
          from = result[0].pos + from;
          to = from + result[0].node.nodeSize;
        } else {
          return false;
        }
      }

      const textSelection = TextSelection.create(tr.doc, from, to);
      tr.setSelection(textSelection);

      if (dispatch) {
        tr.scrollIntoView();
        dispatch(tr);
      }
      return true;
    };
  }
}

declare global {
  namespace Remirror {
    interface AllExtensions {
      styledLink: StyledLinkExtension;
    }
  }
}
