import { processImage } from "../../../features/catalogs/catalog.util";
import { HtmlText } from "../../../components/HtmlText";
import { TextPlaceholder } from "../../../components/TextPlaceholder";
import { IconTypes } from "../../../components/icons.types";
import { CatalogBuilder } from "../../../features/catalogs/catalog.builder";
import { createTemplatePageItem } from "../../../features/catalogs/catalog.item";
import { TemplateItemRef } from "../../../features/design/design.types";
import { Typography } from "@mui/material";

export const templateSortBy = [
  { name: "Modified", value: "modified", o: -1 },
  { name: "Name", value: "name", o: 1 },
];

export const renderTemplateItem = (
  item: TemplateItemRef,
  preview: boolean,
  isThumb: boolean,
  blockContent?: boolean
) => {
  switch (item.di.type) {
    case "image": {
      if (item.info.src && (preview || item.di.imgMode === "image")) {
        return (
          <div className="image-item">
            <img
              className="content-image"
              src={item.info.src}
              alt={item.info.path}
              title={item.info.path}
              data-pos={item.di.position}
              onLoad={(e) => processImage(e.target as HTMLImageElement)}
              draggable={false}
            />
          </div>
        );
      } else {
        // Enhanced image placeholder with field info
        return (
          <div className="image-field preview-field">
            <div className={`field-icon ${isThumb ? "thumb" : ""}`}>
              <IconTypes.Image />
            </div>
            {!isThumb && item.di.src && (
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ mt: 1, ml: 1 }}
              >
                Field: {item.di.src}
                <br />
                {item.di.position && `Position: ${item.di.position}`}
              </Typography>
            )}
          </div>
        );
      }
    }
    default: {
      return isThumb && !preview ? (
        item.di.text ? (
          <TextPlaceholder ext={item.info.extension} />
        ) : null
      ) : (
        <HtmlText
          content={preview ? item.info?.content : item.di.text}
          scalable={item.di.scale}
          scroll={item.di.exp}
          blockContent={blockContent}
        />
      );
    }
  }
};

export const renderTemplate = (
  builder: CatalogBuilder,
  templateId: string,
  productId: string,
  w: number,
  h: number
) => {
  const item = createTemplatePageItem(
    builder,
    templateId,
    productId,
    w,
    h,
    true
  );
  return (
    <div className="template-body">
      {item.info.templateItems?.map((ti, i) => (
        <div key={i} className="template-item" style={ti.style}>
          {renderTemplateItem(ti, !!productId, true)}
        </div>
      ))}
    </div>
  );
};
