import { useCallback, useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  FormControl,
  Tab,
  Typography,
  FormLabel,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useAtom } from "jotai";
import { OutlinedColorPicker } from "../../../components/ColorPicker";
import { ImagePicker } from "../../../components/ImagePicker";
import { SelectFromList } from "../../../components/SelectFromList";
import { CloseButton } from "../../../components/buttons/CloseButton";
import { ProcessButton } from "../../../components/buttons/ProcessButton";
import { HtmlEditor } from "../../../components/remirror/RemirrorEditor";
import { EMPTY_GUID } from "../../../features/data/data-types";
import {
  Category,
  createCategory,
  mapCategory,
  refreshCategoryMap,
} from "../../../features/products/product";
import { useProducts } from "../../../features/products/product-func";
import { CategoryEditOptions } from "../../../features/products/product-modal-state";
import { saveCategory } from "../../../features/products/product.service";
import { productDbAtom } from "../../../features/products/product.state";
import { templateDbAtom } from "../../../features/templates/template.state";
import { useDbOperations } from "../../data-func";
import { CategoryFields } from "./category-fields";
import { useCategories } from "./category-func";
import { CategoryOptions } from "./category-options";
import { IconTypes } from "../../../components/icons.types";

interface CategoryModalProps extends CategoryEditOptions {
  onClose: () => void;
}

export const CategoryModal = ({
  categoryId,
  onClose,
  onChange,
  onDelete,
}: CategoryModalProps) => {
  const [productDb, setProductDb] = useAtom(productDbAtom);
  const [templates] = useAtom(templateDbAtom);
  const [processing, setProcessing] = useState(false);

  const { renderCategoryDeleteModal, setCategoryToDelete } = useCategories(
    () => {
      onDelete?.();
      onClose();
    }
  );
  const { isReadOnly } = useProducts();

  const { topCategories } = useProducts();
  const [edit, setEdit] = useState<Category>();
  const [tab, setTab] = useState("details");
  const nameRef = useRef<HTMLInputElement>(null);

  const isNew = categoryId === EMPTY_GUID;

  useEffect(() => {
    if (categoryId === null) {
      setEdit(null);
      return;
    }
    const cr = isNew
      ? createCategory(productDb.storageUrl)
      : productDb.categoryMap.get(categoryId);
    setEdit(cr ? { ...cr.group } : null);
    setTab("details");
    if (isNew)
      setTimeout(() => {
        nameRef.current?.focus();
      });
  }, [categoryId, isNew, productDb.categoryMap, productDb.storageUrl]);

  const subCategories = productDb.categoryMap.get(categoryId)?.children?.length;

  const { processDb } = useDbOperations();

  const processSaveCategory = useCallback(async () => {
    processDb(
      async () => {
        return await saveCategory(edit);
      },
      (result) => {
        const categoryMap = new Map(productDb.categoryMap);
        const r = mapCategory(
          result.value,
          productDb.optionMap,
          productDb.storageUrl
        );
        categoryMap.set(r.group.id, r);

        refreshCategoryMap(categoryMap);

        const productMap = new Map(productDb.productMap);
        Array.from(productMap.values())
          .filter((p) => p.product.categoryId === r.group.id)
          .forEach((p) => {
            p.category = r;
            r.totalProductCount++;
          });

        setProductDb({
          ...productDb,
          categoryMap,
          productMap,
          version: productDb.version + 1,
        });
        onChange?.(r);
        setEdit(null);
      },
      setProcessing,
      "Saved category " + edit.name,
      "Error saving category."
    );
  }, [edit, onChange, processDb, productDb, setProductDb]);

  if (!edit) return null;

  return (
    <Dialog open={!!edit} onClose={onClose} fullWidth={true} maxWidth="md">
      <DialogTitle>
        Edit category {edit?.name} <CloseButton onClose={onClose} />
      </DialogTitle>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={(_, newValue: string) => {
              setTab(newValue);
            }}
            sx={{
              mx: 2,
            }}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Details" value="details" />
            <Tab label="Product Fields" value="fields" />
            <Tab label="Variant Options" value="options" />
          </TabList>
        </Box>
        <DialogContent sx={{ height: "70vh" }}>
          <TabPanel value="details" sx={{ pt: 0 }}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{
                "& .MuiFormControl-root": { my: 1 },
              }}
            >
              <div>
                <FormControl fullWidth>
                  <TextField
                    label="Category Name"
                    value={edit.name ?? ""}
                    required
                    onChange={(e) => setEdit({ ...edit, name: e.target.value })}
                    inputRef={nameRef}
                  />
                </FormControl>
              </div>

              <div>
                <FormControl fullWidth>
                  <FormLabel component="legend">Category Description</FormLabel>
                  <HtmlEditor
                    value={edit.description ?? ""}
                    onChange={(html) => setEdit({ ...edit, description: html })}
                    editorId="category-description"
                  />
                  {/* <TextField
                    label="Category Description"
                    value={edit.description ?? ""}
                    onChange={(e) =>
                      setEdit({ ...edit, description: e.target.value })
                    }
                  /> */}
                </FormControl>
              </div>
              {!subCategories && (
                <div>
                  <SelectFromList
                    entries={topCategories
                      .filter((c) => c.group.id !== edit.id)
                      .map((c) => ({
                        value: c.group.id,
                        name: c.group.name,
                      }))}
                    value={edit.parentId}
                    setValue={(parentId) => setEdit({ ...edit, parentId })}
                    label="Parent category"
                    defaultLabel="No parent category"
                  />
                </div>
              )}
              {subCategories > 0 && (
                <Typography>
                  <b>{subCategories}</b> sub-categories
                </Typography>
              )}
              <div>
                <FormControl fullWidth>
                  <TextField
                    label="Rank"
                    value={edit.rank ?? 0}
                    type="number"
                    onChange={(e) =>
                      setEdit({ ...edit, rank: +e.target.value })
                    }
                    inputProps={{ pattern: "[0-9]*" }}
                  />
                </FormControl>
              </div>
              <Box my={1}>
                <OutlinedColorPicker
                  color={edit.color}
                  setColor={(color) => setEdit({ ...edit, color })}
                />
              </Box>
              <Box my={2}>
                <ImagePicker
                  label="Category Thumbnail"
                  path={edit.thumbnail}
                  setPath={(thumbnail) => setEdit({ ...edit, thumbnail })}
                />
              </Box>

              <div>
                <SelectFromList
                  entries={templates.map((t) => ({
                    value: t.template.id,
                    name: t.template.name,
                  }))}
                  value={edit.detailsTemplateId}
                  setValue={(detailsTemplateId) =>
                    setEdit({ ...edit, detailsTemplateId })
                  }
                  label="Detail Card"
                  defaultLabel="-- Default Card --"
                />
              </div>

              <div>
                <SelectFromList
                  entries={templates.map((t) => ({
                    value: t.template.id,
                    name: t.template.name,
                  }))}
                  value={edit.listTemplateId}
                  setValue={(listTemplateId) =>
                    setEdit({ ...edit, listTemplateId })
                  }
                  label="List Card"
                  defaultLabel="-- Default Card --"
                />
              </div>
            </Box>
          </TabPanel>
          <TabPanel value="fields" sx={{ pt: 0 }}>
            <CategoryFields category={edit} onChange={setEdit} />
          </TabPanel>
          <TabPanel value="options" sx={{ pt: 0 }}>
            <CategoryOptions category={edit} onChange={setEdit} />
          </TabPanel>
        </DialogContent>
      </TabContext>
      {!isReadOnly && (
        <DialogActions>
          <Box sx={{ display: "flex", mx: 2, width: "100%" }}>
            {!isNew && productDb.categoryMap.size > 1 && (
              <ProcessButton
                processing={processing}
                onClick={() => setCategoryToDelete(edit)}
                label={<IconTypes.DeleteAction />}
                color="error"
                variant="outlined"
              />
            )}
            <Box flexGrow={1} />

            <Button onClick={onClose}>Close</Button>
            <ProcessButton
              processing={processing}
              onClick={() => {
                processSaveCategory();
                onClose();
              }}
              label="Save"
              disabled={!edit?.name}
            />
          </Box>
        </DialogActions>
      )}
      {renderCategoryDeleteModal()}
    </Dialog>
  );
};
