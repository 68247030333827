export const UserJourneys = {
  Onboarding: {
    Type: "onboarding" as const,
    Name: "Initial Setup",
    Steps: [
      "signup",
      "business_goal",
      "first_product",
      "first_catalog",
      "catalog_product",
      "catalog_published",
    ] as const,
  },
  ProductSetup: {
    Type: "product_setup" as const,
    Name: "Product Management",
    Steps: [
      "add_product",
      "import_preparation",
      "import_products",
      "shopify-import",
      "csv-import",
      "category_setup",
      "create_collections",
      "add_fields",
      "add_variants",
      "bulk_update",
    ] as const,
  },
  CatalogCreation: {
    Type: "catalog_creation" as const,
    Name: "Catalog Creation",
    Steps: [
      "start_catalog",
      "first_page",
      //create
      "use_auto_grid",
      "use_product_list",
      "use_layout",
      "save_as_layout",
      "catalog_template",
      "design_layout",
      //design
      "design_card",
      "design_pages",
      "use_element",
      "catalog_images",
      "edit_text",
      //publish
      "preview",
      "share",
      "download_pdf",
    ] as const,
  },
  Discovery: {
    Type: "discovery" as const,
    Name: "Advanced Features",
    Steps: [
      //account
      "add_user",
      "account_settings",

      //data
      "manage_images",
      "use_parameters",

      //future
      "setup_showroom",
      "setup_orders",
      "use_inventory",
      "upload_pdf",
      "view_analytics",
      "use_automation",

      //conversion
      "upgrade_plan",
    ] as const,
  },
} as const;

export type JourneyKeys = keyof typeof UserJourneys;
export type JourneyType = (typeof UserJourneys)[JourneyKeys]["Type"];

export type JourneyConfig<T extends JourneyType> = Extract<
  {
    [K in JourneyKeys]: {
      Type: (typeof UserJourneys)[K]["Type"];
    } & (typeof UserJourneys)[K];
  }[JourneyKeys],
  { Type: T }
>;

export type JourneySteps<T extends JourneyType> =
  JourneyConfig<T>["Steps"][number];

export type GuideDisplayMode = "expanded" | "collapsed" | "hidden";

// Define types for page configurations
type BasePageConfig = {
  paths: readonly string[];
  mode: GuideDisplayMode;
};

type JourneyPageConfig<T extends JourneyType = JourneyType> = BasePageConfig & {
  type: "journey";
  journeyType: T;
  journeyStep: JourneySteps<T>;
};

// Simple page configuration
type SimplePageConfig = BasePageConfig & {
  type: "simple";
};

// Union type for all page configurations
type PageConfig = JourneyPageConfig | SimplePageConfig;

// // Type guard to check if a config is a journey config
// function isJourneyConfig(config: PageConfig): config is JourneyPageConfig {
//   return config.type === "journey";
// }

// Helper function to create journey page configs with type checking
function createJourneyConfig<T extends JourneyType>(
  config: Omit<JourneyPageConfig<T>, "type"> & { type?: "journey" }
): JourneyPageConfig<T> {
  return {
    type: "journey",
    ...config,
  };
}

export const pageGuideConfigs: readonly PageConfig[] = [
  createJourneyConfig({
    paths: ["/products", "/products/add", "/import/shopify"] as const,
    journeyType: "onboarding",
    journeyStep: "first_product",
    mode: "collapsed" as const,
  }),
  createJourneyConfig({
    paths: ["/catalogs/create"] as const,
    journeyType: "onboarding",
    journeyStep: "first_catalog",
    mode: "collapsed" as const,
  }),
  createJourneyConfig({
    paths: ["/design/catalog"] as const,
    journeyType: "catalog_creation",
    journeyStep: "first_page",
    mode: "collapsed" as const,
  }),
  {
    type: "simple" as const,
    paths: ["/start"] as const,
    mode: "hidden" as const,
  },
] as const;

export interface JourneyProgress<T extends JourneyType = JourneyType> {
  journeyType: T;
  stepKey: JourneySteps<T>;
  completedAt?: string;
  source?: string;
  metadata?: Record<string, unknown>;
}
