import { useState, useEffect, useCallback } from "react";
import {
  saveEventLog,
  getSessionId,
} from "../features/analytics/analyticsBatcher";
import {
  PageLocation,
  buildLocationUrl,
  logPageView,
} from "../services/logging";
import { LogProviderProps, LogContext } from "./log.context";

export const LogProvider: React.FC<LogProviderProps> = ({
  children,
}: LogProviderProps) => {
  const [, setUrl] = useState("");

  useEffect(() => {
    const handleUnload = () => {
      saveEventLog("beforeunload");
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState !== "visible") {
        saveEventLog("visibilitychange");
      }
    };

    window.addEventListener("beforeunload", handleUnload);
    window.document.addEventListener(
      "visibilitychange",
      handleVisibilityChange
    );

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
      window.document.removeEventListener(
        "visibilitychange",
        handleVisibilityChange
      );
      handleUnload();
    };
  }, []);

  const logPage = useCallback((location: PageLocation) => {
    const u = buildLocationUrl(location);
    setUrl((eu) => {
      if (eu !== u) {
        logPageView(u);
      }
      return u;
    });
  }, []);

  useEffect(() => {
    logPage(window.location);
  }, [logPage]);

  return (
    <LogContext.Provider value={{ logPage, sessionId: getSessionId() }}>
      {children}
    </LogContext.Provider>
  );
};
