import {
  Box,
  Button,
  Typography,
  Divider,
  Tooltip,
  IconButton,
} from "@mui/material";
import { forwardRef } from "react";
import { IconTypes } from "../../components/icons.types";
import { useQuickGuide } from "../../features/guide/useQuickGuide";
import { getFeature } from "../../features/guide/features";

export const CollapsedGuideBar = forwardRef<HTMLDivElement>((_props, ref) => {
  const { visibleGuideSteps, updateGuideDisplay } = useQuickGuide();
  const step = visibleGuideSteps.find((step) => !step.completed);

  const feature = getFeature(step?.feature);

  return (
    <Box
      ref={ref}
      sx={{
        backgroundColor: "darkBackground",
        borderRadius: 2,
        p: 0.5,
        display: "flex",
        alignItems: "center",
        boxShadow: 2,
        "&:hover": {
          boxShadow: 3,
        },
      }}
    >
      {/* Main button that opens the guide */}
      <Button
        variant="contained"
        disableElevation
        startIcon={<IconTypes.MenuOpen />}
        onClick={() => updateGuideDisplay("expanded", true)}
        sx={{
          borderRadius: 1.5,
          flex: 1,
          mr: 0.5,
          justifyContent: "flex-start",
          "& .MuiButton-startIcon": {
            mr: 1,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="caption" sx={{ opacity: 0.8, lineHeight: 1 }}>
            Next Step
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {step.label ?? feature?.title}
          </Typography>
        </Box>
      </Button>

      {/* Divider line */}
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          mx: 0.5,
          borderColor: "rgba(255, 255, 255, 0.2)",
        }}
      />

      <Tooltip title="Hide guide" placement="top">
        <IconButton
          size="small"
          onClick={() => updateGuideDisplay("hidden", true)}
        >
          <IconTypes.Close />
        </IconButton>
      </Tooltip>
    </Box>
  );
});
CollapsedGuideBar.displayName = "CollapsedGuideBar";
