import { ReactNode, createContext } from "react";
import { PageLocation } from "../services/logging";

export interface LogProviderProps {
  children?: ReactNode;
}

interface LogContextProps {
  logPage: (location: PageLocation) => void;
  sessionId: string;
}

export const LogContext = createContext<LogContextProps>(null);
