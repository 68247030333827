import * as Sentry from "@sentry/react";

import {
  GA_DEBUG_MODE,
  GA_TRACKING_ID,
  trackEvent,
} from "../features/analytics/analytics";
import appInsights from "./appInsights";
import { bootIntercom } from "./intercom";

//import Hotjar from "@hotjar/browser";
import { initSentry } from "./sentry";
import { AppSession } from "../features/accounts/account";
import { callGtag } from "./ga";
import { getUserConsent } from "./consent";

export const initLogging = () => {
  initSentry();
  //Hotjar.init(415148, 6);
};

export type LoginTime = {
  diffHours: number;
  lastLoginDate?: Date;
};

const checkLastLoginHours = (): LoginTime => {
  const lastLogin = localStorage.getItem("last_login");
  const lastLoginDate = lastLogin ? new Date(lastLogin) : undefined;
  const now = new Date();
  const diff = lastLoginDate ? now.getTime() - lastLoginDate.getTime() : 0;
  const diffHours = Math.ceil(diff / (1000 * 60 * 60));
  localStorage.setItem("last_login", now.toISOString());
  return { diffHours, lastLoginDate };
};

export const setUserAuthLogging = (session: AppSession) => {
  const lastLogin = checkLastLoginHours();

  appInsights.setAuthenticatedUserContext(
    session.user.email,
    session.account.namespace
  );

  appInsights.addTelemetryInitializer((envelope) => {
    envelope.data.plan = session.account.planCode;
  });

  Sentry.setUser({
    id: session.account.id,
    email: session.user.email,
    name: session.account.namespace,
  });

  Sentry.setContext("account", {
    accountId: session.account.id,
    plan: session.account.planCode,
    name: session.user.name,
  });

  Sentry.setTag("account.age", session.account.age);
  Sentry.setTag("account.plan", session.account.planCode);
  Sentry.setTag("account.namespace", session.account.namespace);
  Sentry.setTag("account.id", session.account.id);
  Sentry.setTag("account.sessions", session.account.summary.sessions);
  if (session.account.gateway)
    Sentry.setTag("account.gateway", session.account.gateway);
  if (session.account.country)
    Sentry.setTag("account.country", session.account.country);

  const user_id = session.account.namespace + "-" + session.user.id;

  callGtag("config", GA_TRACKING_ID, {
    //user_id: session.user.id,
    user_id,
  });

  callGtag("set", "user_properties", {
    //user_id: session.user.id,
    user_id,
    namespace: session.account.namespace,
    plan: session.account.planCode,
    age: session.account.age,
  });

  trackEvent({
    type: "trace",
    category: "session",
    action: "start",
    label: session.provider,
    internal: true,
  });

  const consent = getUserConsent();
  const isAll = consent.analytics;
  trackEvent({
    category: "cookie-consent",
    action: isAll ? (consent.essential ? "all" : "not-set") : "selected-cookie",
    label: isAll ? undefined : consent.analytics ? "analytics " : "",
    internal: true,
  });

  bootIntercom(session, lastLogin);
};

export const clearUserAuthLogging = () => {
  appInsights.clearAuthenticatedUserContext();
  Sentry.withScope((scope) => scope.clear());
  Intercom("shutdown");
  callGtag("config", GA_TRACKING_ID, {
    user_id: null,
  });
};

export const logSignIn = (provider: string) => {
  callGtag("event", "login", {
    method: provider,
  });
  trackEvent({
    category: "app",
    action: "login",
    label: provider,
    internal: true,
  });
};

export const logError = (error, info?: string) => {
  appInsights.trackException({
    exception: error,
    properties: {
      info,
    },
  });
  Sentry.withScope((scope) => {
    if (info)
      scope.setExtras({
        info,
      });
    Sentry.captureException(error);
  });
  callGtag("event", "exception", {
    description: error,
    fatal: false,
    info,
  });
  trackEvent({
    type: "error",
    category: "app",
    action: "error",
    label: `${error?.message ?? "n/a"} ${info ?? ""}`,
    internal: true,
  });
};

export const logPageView = (url: string) => {
  trackEvent({
    type: "trace",
    category: "page",
    action: "view",
    label: url,
    internal: true,
  });
  appInsights.trackPageView();
  callGtag("config", GA_TRACKING_ID, { debug_mode: GA_DEBUG_MODE });
  Intercom("update");
  //Hotjar.stateChange(url);
};

export type PageLocation = {
  search: string;
  pathname: string;
  hash: string;
};

export const buildLocationUrl = (location: PageLocation) => {
  const searchParams = new URLSearchParams(location.search);
  const s = Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
    if (key.indexOf("token") < 0) {
      acc = `${acc}${acc ? "&" : ""}${key}=${value}`;
    }
    return acc;
  }, "");
  const url = `${location.pathname}${s ? "?" : ""}${s}${location.hash}`;
  return url;
};
