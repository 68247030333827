import { JourneySteps, JourneyType } from "./journey";
import { SvgIconComponent } from "@mui/icons-material";
import { IconTypes } from "../../components/icons.types";

export const featureKeys = [
  "shopify",
  "csv",
  "catalogs",
  "products",
  "categories",
  "collections",
  "images",
  "account",
  "catalogTemplates",
  "autoGrid",
  "productList",
  "productCard",
  "layout",
] as const;

// Create type from the keys
export type FeatureKey = (typeof featureKeys)[number];

// Base feature interface
export interface Feature<T extends JourneyType = JourneyType> {
  key: string;
  title: string;
  description: string;
  image?: string;
  icon?: SvgIconComponent;
  path?: string;
  helpUrl?: string;
  journeyType?: T;
  completionStep?: JourneySteps<T>;
  completionParams?: Record<string, string>;
}

// Type for the features record enforcing all keys are present
export type Features = {
  [K in FeatureKey]: Feature;
};

// Define features with type safety
export const appFeatures: Features = {
  shopify: {
    key: "shopifyImport",
    title: "Import from Shopify",
    description: "Import your existing Shopify products in minutes",
    image: "/images/shopify.png",
    icon: IconTypes.Store,
    path: "/import/shopify",
    helpUrl:
      "https://help.catalogmachine.com/en/articles/10432942-importing-products-from-shopify-v2",
    journeyType: "product_setup",
    completionStep: "shopify-import",
    completionParams: { service: "shopify" },
  },
  csv: {
    key: "csvImport",
    title: "Import from CSV / Excel",
    description: "Import your products from a spreadsheet",
    image: "/images/excel.png",
    icon: IconTypes.Upload,
    path: "/products?import=csv",
    helpUrl:
      "https://help.catalogmachine.com/en/articles/10451242-importing-products-from-csv-v2",
    journeyType: "product_setup",
    completionStep: "csv-import",
    completionParams: { service: "csv" },
  },
  catalogs: {
    key: "createCatalog",
    title: "Create Catalogs",
    description: "Create your product catalogs",
    image: "/images/catalogue.png",
    icon: IconTypes.Catalog,
    path: "/catalogs",
    helpUrl:
      "https://help.catalogmachine.com/en/articles/9269964-build-your-product-catalog-v2",
    journeyType: "onboarding",
    completionStep: "first_catalog",
  },
  products: {
    key: "addProducts",
    title: "Add / edit products",
    description: "Create your products in the grid",
    image: "/images/products.png",
    icon: IconTypes.Add,
    path: "/products",
    helpUrl:
      "https://help.catalogmachine.com/en/articles/10458534-managing-products-v2",
    journeyType: "onboarding",
    completionStep: "first_product",
  },
  images: {
    key: "manage_images",
    title: "Upload / edit Images",
    description: "Manage images",
    image: "/images/image-gallery.png",
    path: "/images",
    icon: IconTypes.Image,
    journeyType: "discovery",
    completionStep: "manage_images",
  },
  account: {
    key: "manageAccount",
    title: "Manage account",
    description: "Update your account settings",
    image: "/images/user.png",
    path: "/account",
    journeyType: "discovery",
    completionStep: "account_settings",
  },
  catalogTemplates: {
    key: "catalogTemplates",
    title: "Start with Sample Catalog",
    description:
      "Begin with pre-made sample catalog with products and customize them for your needs",
    image: "/images/product-box.png",
    icon: IconTypes.Templates,
    path: "/catalogs/templates",
  },
  categories: {
    key: "categories",
    title: "Setup categories",
    description: "Manage your product categories",
    icon: IconTypes.Category,
    path: "/products/categories",
    helpUrl:
      "https://help.catalogmachine.com/en/articles/10458534-managing-products-v2",
    journeyType: "product_setup",
    completionStep: "category_setup",
  },
  collections: {
    key: "collections",
    title: "Create Collections",
    description: "Organize your product",
    icon: IconTypes.Collection,
    path: "/products/collections",
    helpUrl:
      "https://help.catalogmachine.com/en/articles/10458534-managing-products-v2",
    journeyType: "product_setup",
    completionStep: "create_collections",
  },
  autoGrid: {
    key: "autoGrid",
    title: "Auto Grid",
    description: "Quickly add multiple products",
    icon: IconTypes.Grid,
    helpUrl:
      "https://help.catalogmachine.com/en/articles/9289226-build-product-grids-instantly-with-autogrid-v2",

    journeyType: "catalog_creation",
    completionStep: "use_auto_grid",
  },
  productList: {
    key: "productList",
    title: "Product / Price List",
    description: "Create product price tables",
    icon: IconTypes.ProductList,
    helpUrl:
      "https://help.catalogmachine.com/en/articles/9296058-create-product-and-price-lists-v2",

    journeyType: "catalog_creation",
    completionStep: "use_product_list",
  },
  productCard: {
    key: "productCard",
    title: "Product Cards",
    description: "Define product display styles",
    icon: IconTypes.ProductCard,
    helpUrl:
      "https://help.catalogmachine.com/en/articles/9768970-product-cards-v2",
    journeyType: "catalog_creation",
    completionStep: "design_card",
  },
  layout: {
    key: "layout",
    title: "Page Layout",
    description: "Add page template",
    icon: IconTypes.Layout,
    journeyType: "catalog_creation",
    completionStep: "use_layout",
  },
} as const;

// Helper type to get feature type by key
export type FeatureByKey<K extends FeatureKey> = Features[K];

// Helper function to get feature with type safety
export function getFeature<K extends FeatureKey>(key: K): FeatureByKey<K> {
  if (!key) return null;
  return appFeatures[key];
}
