import { Tooltip, IconButton, SxProps } from "@mui/material";
import { IconTypes } from "../icons.types";
import { trackEvent } from "../../features/analytics/analytics";

const HelpButton = ({
  helpUrl,
  title,
  variant,
  sx,
}: {
  helpUrl?: string;
  title?: string;
  variant?: "small" | "large";
  sx?: SxProps;
}) => {
  if (!helpUrl) return null;

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    trackEvent({ category: "help", action: "click", label: helpUrl });
    window.open(helpUrl, "_blank");
  };

  return (
    <Tooltip title={title || "Learn more"}>
      <IconButton
        size="small"
        sx={{
          color: "text.secondary",
          "&:hover": { color: "primary.main" },
          ...sx,
        }}
        onClick={handleClick}
      >
        <IconTypes.Help fontSize={variant} />
      </IconButton>
    </Tooltip>
  );
};
export default HelpButton;
