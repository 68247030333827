import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Link,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Alert,
} from "@mui/material";
import { IconTypes } from "../../components/icons.types";
import { StepIconProps } from "@mui/material/StepIcon";
import { HELP_CENTER } from "../../services/site.config";
import { ContextAwareGuide, GuideContext } from "./ContextAwareGuide";
import { devLog } from "../../services/util";
import { useQuickGuide } from "../../features/guide/useQuickGuide";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { catalogDbAtom } from "../../features/catalogs/catalog.state";
import { useAtom } from "jotai";
import { Catalog } from "../../features/catalogs/catalogs";
import { showIntercom } from "../../services/intercom";
import { productDbAtom } from "../../features/products/product.state";
import { useDialogs } from "../../contexts/useDialogs";
import { BaseStep } from "../../features/guide/guide.steps";
import { getFeature } from "../../features/guide/features";

export const ExpandedGuidePanel = forwardRef<HTMLDivElement>((_props, ref) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [, setSearchParams] = useSearchParams();
  const [catalogDb] = useAtom(catalogDbAtom);
  const [productDb] = useAtom(productDbAtom);
  const { productModalApi } = useDialogs();
  const [currentCatalog, setCurrentCatalog] = useState<Catalog | null>(null);
  const [lastStep, setLastStep] = useState<string>();

  const {
    guideLoaded,
    guideState,
    updateGuideState,
    visibleGuideSteps,
    hasCompletedGuideSteps,
    updateGuideDisplay,
  } = useQuickGuide();

  const { expandedStep } = guideState;

  const CustomStepIcon = (props: StepIconProps & { step: BaseStep }) => {
    const { step } = props;

    const feature = getFeature(step.feature);

    // Get the appropriate icon component
    const StepIcon = feature?.icon
      ? feature.icon
      : visibleGuideSteps[Number(props.icon) - 1]?.icon ||
        IconTypes.CheckCircle;

    return (
      <Box
        sx={{
          color: step.completed
            ? "success.main"
            : props.active
            ? "primary.main"
            : "text.secondary",
          display: "flex",
          alignItems: "center",
        }}
      >
        <StepIcon />
      </Box>
    );
  };

  useEffect(() => {
    if (!guideLoaded) return;
    if (
      !visibleGuideSteps.some((step) => step.key === expandedStep) ||
      expandedStep !== lastStep
    ) {
      const firstUncompletedStep = visibleGuideSteps.find(
        (step) => !step.completed
      );
      updateGuideState({ expandedStep: firstUncompletedStep?.key });
      setLastStep(firstUncompletedStep?.key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guideLoaded, visibleGuideSteps, lastStep]);

  useEffect(() => {
    if (location.pathname.includes("/design/catalog/")) {
      const catalogId = location.pathname.split("/").pop();
      const matchingCatalog = catalogDb.find(
        (cat) => cat.catalog.id === catalogId
      );
      if (
        matchingCatalog &&
        matchingCatalog.catalog.id !== currentCatalog?.id
      ) {
        setCurrentCatalog(matchingCatalog.catalog);
      }
    } else {
      setCurrentCatalog(null);
    }
  }, [catalogDb, currentCatalog?.id, location.pathname]);

  const guideContext = useMemo<GuideContext>(
    () => ({
      hasCatalog: Boolean(catalogDb?.length),
      hasProducts: Boolean(productDb?.productMap.size),
      isInCatalogDesigner: location.pathname.includes("/design/catalog"),
      currentCatalogId: currentCatalog?.id,
      navigateToCatalogDesigner: (start: string) => {
        if (currentCatalog?.id) {
          setSearchParams({ start });
        } else if (catalogDb?.[0]?.catalog.id) {
          navigate(
            `/design/catalog/${catalogDb?.[0]?.catalog.id}?start=${start}`
          );
        }
      },
      openProductEditor: () => {
        const productId = Array.from(productDb.productMap.keys())[0];
        productModalApi.openEditProduct({ productId, startTab: "variants" });
      },
    }),
    [
      catalogDb,
      currentCatalog?.id,
      location.pathname,
      navigate,
      productDb.productMap,
      productModalApi,
      setSearchParams,
    ]
  );

  const handleStepAction = useCallback((key: string) => {
    devLog("Step action:", key);
    if (key === "support") {
      showIntercom();
    }
  }, []);

  const handleStepClick = (key: string) => {
    const step = expandedStep === key ? null : key;
    updateGuideState({ expandedStep: step });
    setLastStep(step);
  };

  return (
    <Paper
      ref={ref}
      elevation={3}
      sx={{
        width: 350,
        p: 0,
        pr: 0.2,
        borderRadius: 2,
        backgroundColor: "#fafafa",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          pb: hasCompletedGuideSteps ? 0 : 2,
        }}
      >
        <Typography
          variant="h6"
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
        >
          <IconTypes.MenuOpen color="primary" />
          Quick Guide
        </Typography>
        <Box>
          <IconButton
            size="small"
            component={Link}
            href={HELP_CENTER}
            target="_blank"
          >
            <IconTypes.Help />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => updateGuideDisplay("collapsed", true)}
          >
            <IconTypes.ExpandMore />
          </IconButton>
        </Box>
      </Box>

      {hasCompletedGuideSteps && (
        <Box
          sx={{
            px: 2,
            py: 1,
            display: "flex",
            alignItems: "center",
            gap: 1,
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Button
            size="small"
            startIcon={
              !guideState.hideCompleted ? (
                <IconTypes.VisibilityOff />
              ) : (
                <IconTypes.VisibilityOn />
              )
            }
            onClick={() =>
              updateGuideState({ hideCompleted: !guideState.hideCompleted })
            }
            sx={{ fontSize: "0.8125rem" }}
          >
            {guideState.hideCompleted ? "Show Completed" : "Hide Completed"}
          </Button>
        </Box>
      )}

      <Box
        sx={{
          maxHeight: "calc(100vh - 200px)",
          overflowY: "auto",
          p: 2,
        }}
      >
        <Stepper activeStep={-1} orientation="vertical">
          {visibleGuideSteps.map((step) => {
            const feature = getFeature(step.feature);
            return (
              <Step key={step.key} expanded={expandedStep === step.key}>
                <StepLabel
                  optional={
                    step.completed ? (
                      <Typography variant="caption" color="success.main">
                        Completed
                      </Typography>
                    ) : null
                  }
                  onClick={() => handleStepClick(step.key)}
                  slots={{
                    stepIcon: (iconProps) => (
                      <CustomStepIcon {...iconProps} step={step} />
                    ),
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                      cursor: "pointer",
                    }}
                  >
                    {step.label || feature?.title}
                    {expandedStep === step.key ? (
                      <IconTypes.ExpandLess fontSize="small" />
                    ) : (
                      <IconTypes.ExpandMore fontSize="small" />
                    )}
                  </Box>
                </StepLabel>
                <StepContent>
                  <Typography color="text.secondary" sx={{ mt: 1, mb: 2 }}>
                    {step.description || feature?.description}
                  </Typography>

                  {step.tips && (
                    <Alert severity="info" sx={{ mb: 2, px: 1 }} icon={false}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          mb: 1,
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <IconTypes.Info fontSize="small" color="primary" />{" "}
                        Tips:
                      </Typography>
                      <Box component="ul" sx={{ m: 0, pl: 2 }}>
                        {step.tips.map((tip, i) => (
                          <Typography component="li" key={i} variant="body2">
                            <div
                              dangerouslySetInnerHTML={{ __html: tip }}
                            ></div>
                          </Typography>
                        ))}
                      </Box>
                    </Alert>
                  )}

                  {step.substeps.length > 0 && (
                    <ContextAwareGuide
                      steps={[step]}
                      context={guideContext}
                      onStepAction={handleStepAction}
                    />
                  )}
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </Paper>
  );
});

ExpandedGuidePanel.displayName = "ExpandedGuidePanel";
