import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  guideStateAtom,
  GuideState,
  journeyProgressAtom,
  loadJourneyProgressAtom,
  journeyStateAtom,
} from "./journey.state";
import { JourneySteps, JourneyType } from "./journey";
import { useCallback, useMemo } from "react";
import { useAuth } from "../accounts/useAuth";
import { BaseStep, GuideStep, Substep, guideSteps } from "./guide.steps";
import { trackEvent } from "../analytics/analytics";
import { FeatureKey, getFeature } from "./features";

export const useQuickGuide = () => {
  const progress = useAtomValue(journeyProgressAtom);
  const loadProgress = useSetAtom(loadJourneyProgressAtom);
  const journeyState = useAtomValue(journeyStateAtom);

  const [guideState, setGuideState] = useAtom(guideStateAtom);
  const { hasPermission } = useAuth();

  const updateGuideState = useCallback(
    (updates: Partial<GuideState>) => {
      setGuideState((prev) => ({ ...prev, ...updates }));
    },
    [setGuideState]
  );

  const hasCompletedJourneyStep = useCallback(
    <T extends JourneyType>(
      feature?: FeatureKey,
      journeyType?: T,
      stepKey?: JourneySteps<T>
    ) => {
      const f = getFeature(feature);
      if (f && f.completionStep && f.journeyType) {
        return progress[f.journeyType]?.has(f.completionStep);
      }
      if (!journeyType || !stepKey) return false;
      return progress[journeyType]?.has(stepKey);
    },
    [progress]
  );

  const checkSubstepCompletion = useCallback(
    (substep: Substep) => {
      return hasCompletedJourneyStep(
        substep.feature,
        substep.journeyType,
        substep.completionStep
      );
    },
    [hasCompletedJourneyStep]
  );

  const checkStepCompletion = useCallback(
    (step: GuideStep) => {
      const isStepCompleted = hasCompletedJourneyStep(
        step.feature,
        step.journeyType,
        step.completionStep
      );

      const areAllSubstepsCompleted = step.substeps.every(
        (substep) =>
          (!substep.permission || hasPermission(substep.permission)) &&
          checkSubstepCompletion(substep)
      );

      return isStepCompleted || areAllSubstepsCompleted;
    },
    [hasCompletedJourneyStep, checkSubstepCompletion, hasPermission]
  );

  const isStepVisible = useCallback(
    (step: BaseStep) => {
      if (step.permission && !hasPermission(step.permission)) {
        return false;
      }

      const isCompleted =
        "substeps" in step
          ? checkStepCompletion(step as GuideStep)
          : checkSubstepCompletion(step as Substep);

      return !guideState.hideCompleted || !isCompleted;
    },
    [
      guideState.hideCompleted,
      checkStepCompletion,
      checkSubstepCompletion,
      hasPermission,
    ]
  );

  const processStep = useCallback(
    (step: GuideStep) => {
      const processedSubsteps = step.substeps.map((substep) => ({
        ...substep,
        completed: checkSubstepCompletion(substep),
      }));

      const processedStep = {
        ...step,
        completed: checkStepCompletion(step),
        substeps: processedSubsteps,
      };

      return processedStep;
    },
    [checkStepCompletion, checkSubstepCompletion]
  );

  const visibleGuideSteps = useMemo(() => {
    return guideSteps.map(processStep).filter((step) => {
      if (!isStepVisible(step)) return false;
      step.substeps = step.substeps.filter(isStepVisible);
      return step.substeps.length > 0;
    });
  }, [isStepVisible, processStep]);

  const hasCompletedGuideSteps = useMemo(
    () =>
      guideSteps.some(
        (step) =>
          (!step.permission || hasPermission(step.permission)) &&
          checkStepCompletion(step)
      ),
    [checkStepCompletion, hasPermission]
  );

  const updateGuideDisplay = useCallback(
    (displayMode: GuideState["displayMode"], byUser = false) => {
      if (byUser) {
        updateGuideState({ displayMode, userMode: displayMode });
        trackEvent({ category: "guide", action: displayMode });
      } else {
        updateGuideState({ displayMode });
      }
    },
    [updateGuideState]
  );

  return {
    journeyProgress: progress,
    loadJourneyProgress: loadProgress,
    guideState,
    updateGuideState,
    //hasCompletedJourneyStep,
    checkSubstepCompletion,
    guideLoaded: journeyState.loaded,
    visibleGuideSteps,
    hasCompletedGuideSteps,
    updateGuideDisplay,
  };
};
