import {
  Box,
  Typography,
  Card,
  CardActionArea,
  CardContent,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Feature } from "../../features/guide/features";
import { JourneyType } from "../../features/guide/journey";
import HelpButton from "../buttons/HelpButton";

type CardVariant = "default" | "primary" | "outlined" | "compact";

interface FeatureCardProps<T extends JourneyType = JourneyType>
  extends React.PropsWithChildren {
  feature: Feature<T>;
  variant?: CardVariant;
  onClick?: () => void;
}

export const FeatureCard = <T extends JourneyType>({
  feature,
  variant = "default",
  onClick,
  children,
}: FeatureCardProps<T>) => {
  if (variant === "compact") {
    return <QuickActionCard feature={feature} />;
  }

  return (
    <Card
      elevation={variant !== "primary" ? 1 : 0}
      sx={{
        height: "100%",
        border: 1,
        borderColor: variant === "primary" ? "primary.main" : "divider",
        borderWidth: variant === "primary" ? 2 : 1,
      }}
    >
      <CardActionArea
        component={RouterLink}
        to={feature.path}
        onClick={onClick}
      >
        <CardContent>
          <FeatureCardContent feature={feature} />
        </CardContent>
      </CardActionArea>

      {children}
    </Card>
  );
};

const QuickActionCard = <T extends JourneyType>({
  feature,
}: {
  feature: Feature<T>;
}) => (
  <Card
    component={RouterLink}
    to={feature.path}
    sx={{
      textAlign: "center",
      minWidth: ["100%", 200],
      textDecoration: "none",
      zIndex: 1,
    }}
  >
    <CardActionArea>
      <CardContent sx={{ mt: 1 }}>
        <Box
          component="img"
          src={feature.image}
          alt={feature.title}
          sx={{
            maxWidth: 64,
            opacity: 0.7,
            transition: "opacity 0.2s",
            "&:hover": {
              opacity: 1,
            },
          }}
        />
        <Box sx={{ mt: 2 }}>
          <Typography variant="button">{feature.title}</Typography>
        </Box>
      </CardContent>
    </CardActionArea>
  </Card>
);

// Separate the content rendering for reusability
const FeatureCardContent = <T extends JourneyType>({
  feature,
}: {
  feature: Feature<T>;
}) => (
  <>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        mb: 2,
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {feature.image && (
          <Box
            component="img"
            src={feature.image}
            alt={feature.title}
            sx={{ width: 48, height: 48, mr: 2 }}
          />
        )}
        {feature.icon && !feature.image && (
          <feature.icon sx={{ width: 48, height: 48, mr: 2 }} />
        )}
        <Typography variant="h6">{feature.title}</Typography>
      </Box>

      <HelpButton helpUrl={feature.helpUrl} />
    </Box>

    <Typography color="textSecondary">{feature.description}</Typography>
  </>
);
