import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useAtom } from "jotai";
import { FileUpload, FileUploadRef } from "../../../components/ImageUpload";
import { CloseButton } from "../../../components/buttons/CloseButton";
import { ProcessButton } from "../../../components/buttons/ProcessButton";
import { ROOT_FOLDER, getImageUrl } from "../../../features/images/image";
import { addImageToDb } from "../../../features/images/image.service";
import { imageDbAtom } from "../../../features/images/image.state";
import { FolderSelect } from "../image-components";
import { ImageModalParams, ImageModal } from "./ImageModal";
import { useSnackbar } from "notistack";

export const ImageUploadModal = (params: ImageModalParams) => {
  return (
    <ImageModal
      open={!!params.options}
      title="Image Upload"
      onClose={params.onClose}
    >
      <DialogTitle>
        <div>Upload file{params.options?.multi ? "s" : ""}</div>
        <CloseButton onClose={params.onClose} />
      </DialogTitle>
      <ImageUploadContent {...params} />
    </ImageModal>
  );
};

export const ImageUploadContent = ({ options, onClose }: ImageModalParams) => {
  const [imageDb, setImageDb] = useAtom(imageDbAtom);
  const ref = useRef<FileUploadRef>(null);

  const [uploadOptions, setUploadOptions] = useState({
    ...options,
    folder: options?.folder || ROOT_FOLDER,
  });
  const [uploading, setUploading] = useState(false);
  const [count, setCount] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!options) {
      setUploading(false);
      ref.current?.reset();
    }
  }, [options]);

  return (
    <>
      <DialogContent>
        {(!options.path || options.canSelectFolder) && (
          <Box sx={{ mb: 2, mt: 1 }}>
            <FolderSelect
              folder={uploadOptions.folder}
              label="Upload to a folder"
              onChange={(f) =>
                setUploadOptions({ ...uploadOptions, folder: f })
              }
            />
          </Box>
        )}
        <FileUpload
          ref={ref}
          options={uploadOptions}
          minHeight="50vh"
          onUploadFinish={(success) => {
            setUploading(false);
            if (success) onClose();
            else enqueueSnackbar("Error uploading image", { variant: "error" });
          }}
          onFileUpload={(path, fileSize, error) => {
            if (!error) {
              const db = addImageToDb(imageDb, path, fileSize);
              setImageDb(db);
              options.onSelect?.(path, getImageUrl(db.storageUrl, path));
            }
          }}
          onChange={(c) => setCount(c)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <ProcessButton
          processing={uploading}
          color="primary"
          onClick={() => {
            setUploading(true);
            ref.current?.upload();
          }}
          label={`Upload ${count > 1 ? count + " images" : ""}`}
          disabled={!count || !uploadOptions.folder}
        />
      </DialogActions>
    </>
  );
};
