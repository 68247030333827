import { Box, Fade } from "@mui/material";
import { sessionAtom } from "../../features/accounts/account.state";
import { useAtom } from "jotai";
import { useQuickGuide } from "../../features/guide/useQuickGuide";
import { ExpandedGuidePanel } from "./ExpandedGuidePanel";
import { CollapsedGuideBar } from "./CollapsedGuideBar";

const QuickGuide = () => {
  const [session] = useAtom(sessionAtom);

  const { guideState } = useQuickGuide();

  const { displayMode } = guideState;

  // useEffect(() => {
  //   if (!guideLoaded) return;
  //   updateGuideState({ expandedStep: visibleGuideSteps[0]?.key });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [displayMode, guideLoaded]);

  if (!session?.account) {
    return null;
  }

  if (displayMode === "hidden") {
    return null;
  }

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 80,
        right: 20,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Fade in>
        {displayMode === "expanded" ? (
          <ExpandedGuidePanel />
        ) : (
          <CollapsedGuideBar />
        )}
      </Fade>
    </Box>
  );
};

export default QuickGuide;
