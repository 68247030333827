import { trackEvent } from "../analytics/analytics";
import apiClient, { OperationResult } from "../../services/apiClient";
import { EMPTY_GUID } from "../data/data-types";
import { Template, TemplateRef } from "./templates";
import { journeyManager } from "../guide/journeyManager";

export const getTemplatesDb = async (): Promise<TemplateRef[]> => {
  const response = await apiClient.get<Template[]>("/catalogs/templates");
  return response.data.map((template) => ({
    template,
  }));
};

export const saveTemplate = async (
  model: Template
): Promise<OperationResult<Template>> => {
  trackEvent({
    category: "template",
    action: model.id != EMPTY_GUID ? "update" : "create",
    label: model.id + ":" + model.name,
    feature: true,
  });
  const response = await apiClient.post<OperationResult<Template>>(
    "/catalogs/template",
    model
  );
  journeyManager.updateJourneyProgress("catalog_creation", "design_card", {
    card: model.name,
  });
  return response.data;
};

export const deleteTemplate = async (
  templateId: string
): Promise<OperationResult<string>> => {
  trackEvent({ category: "template", action: "delete", label: templateId });
  const response = await apiClient.post<OperationResult<string>>(
    "/catalogs/delete-template?templateId=" + templateId
  );
  return response.data;
};
