import { useRemirrorContext } from "@remirror/react";
import { useCallback, useEffect, useState } from "react";
import { prosemirrorCmCodeToHtml } from "./extensions/cm-code-extension";
import { html as beautify_html } from "js-beautify";
import { HtmlEditorContext, HtmlEditorProps } from "./editor.context";

export const HtmlEditorProvider = (props: HtmlEditorProps) => {
  const [isSourceEdit, setSourceEdit] = useState(false);
  const [darkBkg, setDarkBkg] = useState(false);
  const [lastContent, setLastContent] = useState(props.value);
  const [editedHtml, setEditedHtml] = useState(props.value);

  const { setContent, view } = useRemirrorContext();

  useEffect(() => {
    if (lastContent !== props.value && !isSourceEdit) {
      setContent(props.value);
      setLastContent(props.value);
    }
  }, [isSourceEdit, lastContent, props, props.value, setContent]);

  const toHtml = useCallback(() => {
    const htmlString = prosemirrorCmCodeToHtml(view.state.doc);
    const h = beautify_html(htmlString);
    return h;
  }, [view.state.doc]);

  const onChange = useCallback(() => {
    const value = toHtml();
    if (value !== props.value) {
      props.onChange?.(value);
      setLastContent(value);
    }
    setEditedHtml(value);
  }, [props, toHtml]);

  return (
    <HtmlEditorContext.Provider
      value={{
        onChange,
        isSourceEdit,
        setSourceEdit,
        editedHtml,
        setEditedHtml,
        toHtml,
        darkBkg,
        setDarkBkg,
        fields: props.fields,
        fieldArgs: props.fieldArgs,
        fullScreen: props.fullScreen,
        setFullScreen: props.setFullScreen,
        editorId: props.editorId,
      }}
    >
      {props.children}
    </HtmlEditorContext.Provider>
  );
};
