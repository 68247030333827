import { useCallback, useEffect, useState } from "react";
import { useAtom } from "jotai";
import { Box, Button, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { EmptyState } from "../../../components/EmptyState";
import { FixedPanel } from "../../../components/FixedPanel";
import { IconTypes } from "../../../components/icons.types";
import { useDialogs } from "../../../contexts/useDialogs";
import { CollectionRef } from "../../../features/products/product";
import { useProducts } from "../../../features/products/product-func";
import { productDbAtom } from "../../../features/products/product.state";
import { listCountStyles } from "../../../ui/styles";
import { useBlockRoute } from "../../../ui/useBlockRoute";
import { CollectionEditor } from "./CollectionEditor";
import { CollectionNav } from "./CollectionNav";
import { useCollections } from "./collection-func";
import { RightPanel } from "../../../components/RightPanel";
import { appFeatures } from "../../../features/guide/features";

type Props = {
  isModal?: boolean;
  onClose?(collectionId?: string): void;
  collectionId?: string;
};

export const CollectionListView = ({
  isModal,
  onClose,
  collectionId,
}: Props) => {
  const [productDb] = useAtom(productDbAtom);

  const [searchParams, setSearchParams] = useSearchParams();
  const [isDirty, setDirty] = useState(false);
  const [collection, setCollection] = useState<CollectionRef>();

  const { topCollections } = useProducts();
  const { productModalApi } = useDialogs();

  const { processSaveCollection } = useCollections();

  const { ExitWarningDialog, setIsExit, setChange } = useBlockRoute({
    isDirty,
    autoSave: false,
    onProceed(c, cancelled) {
      if (cancelled) {
        onExit(c?.id);
      } else {
        processSaveCollection(collection.group, true, () => onExit(c?.id));
      }
    },
    target: "a collection",
  });

  const selectCollection = useCallback(
    (id: string, init?: boolean, force?: boolean) => {
      if (!id && topCollections.length === 0) {
        setCollection(null);
        return;
      }

      if (id && id !== collection?.group.id && isDirty && !force) {
        setChange({ id });
        return;
      }

      id = id ?? topCollections[0].group.id;
      const c = productDb.collectionMap.get(id);
      if (!c) return;
      setCollection({
        ...c,
        group: {
          ...c.group,
          items: [...c.group.items],
        },
      });
      setDirty(false);

      if (!init && !isModal) {
        const r = productDb.collectionMap.get(id);
        if (r) {
          searchParams.set("collection", r.handle);
        } else {
          searchParams.delete("collection");
        }
        setSearchParams(searchParams);
      }
    },
    [
      collection?.group.id,
      isDirty,
      isModal,
      productDb.collectionMap,
      searchParams,
      setChange,
      setSearchParams,
      topCollections,
    ]
  );

  const onExit = useCallback(
    (id?: string) => {
      if (id) setTimeout(() => selectCollection(id, false, true));
      else onClose?.(collection?.group.id);
      setChange(null);
      setDirty(false);
    },
    [collection?.group.id, onClose, selectCollection, setChange]
  );

  const openEdit = useCallback(
    (collectionId?: string, parentId?: string, withDelete?: boolean) => {
      if (withDelete)
        productModalApi.openCollectionDelete({
          collectionId,
          onDelete: () => {
            selectCollection(null);
          },
        });
      else
        productModalApi.openCollectionAttrs({
          collectionId,
          parentId,
          onChange: (id) => {
            selectCollection(id);
          },
        });
    },
    [productModalApi, selectCollection]
  );

  useEffect(() => {
    if (!productDb) return;

    if (
      topCollections.length > 0 &&
      (!collection ||
        (collectionId && !productDb.collectionMap.has(collectionId)))
    ) {
      let col = topCollections[0];
      if (collectionId) {
        col = productDb.collectionMap.get(collectionId);
      } else if (searchParams.has("collection")) {
        const n = searchParams.get("collection");
        col = Array.from(productDb.collectionMap.values()).find(
          (c) => c.handle === n
        );
      }
      selectCollection(col?.group.id, true);
    } else if (!topCollections.length) selectCollection(null);
  }, [
    collection,
    collectionId,
    productDb,
    searchParams,
    selectCollection,
    topCollections,
  ]);

  return (
    <RightPanel
      sx={{
        display: "flex",
        "& .collection": {
          ...listCountStyles,
        },
        "& .collection-header": {
          backgroundColor: isModal ? "#f5f5f5;" : undefined,
          p: 1,
          minHeight: 68,
          borderBottom: isModal ? "1px solid #efefef" : undefined,
          //mb: 0.5,
        },
      }}
    >
      {!collection ? (
        <Box sx={{ flex: 1 }}>
          <FixedPanel>
            <EmptyState
              label="Add your first collection!"
              btnLabel="Add collection"
              imageSrc="/images/open-box.png"
              onAdd={() => openEdit()}
              helpUrl={appFeatures.collections.helpUrl}
            />
          </FixedPanel>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              minWidth: { xs: 250, lg: "20%" },
            }}
          >
            {isModal && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                className="collection-header"
              >
                <Button
                  variant="text"
                  color="inherit"
                  onClick={() => {
                    if (isDirty) {
                      setIsExit(true);
                    } else {
                      onClose?.(collection?.group.id);
                    }
                  }}
                  startIcon={<IconTypes.ArrowBack />}
                  sx={{ mr: 6 }}
                >
                  Back
                </Button>
                <Typography variant="h6">Collections</Typography>
              </Box>
            )}
            <CollectionNav
              collectionId={collection?.group.id}
              onSelect={selectCollection}
              onOpenEdit={openEdit}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
            }}
          >
            <CollectionEditor
              edit={collection}
              onSelect={(id) => {
                if (id && id !== collection?.group.id) selectCollection(id);
              }}
              onOpenEdit={openEdit}
              isDirty={isDirty}
              setDirty={setDirty}
              onUpdate={(c) => {
                setCollection(c);
                setDirty(true);
              }}
            />
          </Box>
        </>
      )}
      {ExitWarningDialog}
    </RightPanel>
  );
};

export default CollectionListView;
