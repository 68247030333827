import { createContext } from "react";
import { EditorField } from "../../features/data/editor";

type HtmlEditorCommonProps = {
  fields?: EditorField[];
  fieldArgs?;
  fullScreen?: boolean;
  setFullScreen?: (on: boolean) => void;
  editorId: string;
};

export type HtmlEditorContentProps = {
  value: string;
  onChange: (value: string) => void;
} & HtmlEditorCommonProps;

export type HtmlEditorProps = {
  children?: React.ReactElement | React.ReactElement[];
} & HtmlEditorContentProps;

export type HtmlEditorContextProps = {
  isSourceEdit?: boolean;
  setSourceEdit?: (on: boolean) => void;
  onChange?: () => void;
  editedHtml: string;
  setEditedHtml: (html: string) => void;
  toHtml: () => string;
  darkBkg?: boolean;
  setDarkBkg?: (on: boolean) => void;
} & HtmlEditorCommonProps;

export const HtmlEditorContext = createContext<HtmlEditorContextProps>(null);
