import { devLog } from "../../services/util";
import { trackEvent } from "../analytics/analytics";
import {
  JourneyType,
  JourneySteps,
  UserJourneys,
  JourneyConfig,
  JourneyProgress,
} from "./journey";
import { JourneyProgressSet } from "./journey.state";

class JourneyManager {
  private static instance: JourneyManager;
  private setters: Map<string, (params: JourneyProgress) => void> = new Map();

  private currentProgress: JourneyProgressSet = {};

  private constructor() {}

  static getInstance() {
    if (!this.instance) {
      this.instance = new JourneyManager();
    }
    return this.instance;
  }

  registerSetter(atomKey: string, setter: (params: JourneyProgress) => void) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.setters.set(atomKey, setter as any); // Need cast due to Map type limitations
  }

  unregisterSetter(atomKey: string) {
    this.setters.delete(atomKey);
  }

  setProgress(progress: JourneyProgressSet) {
    this.currentProgress = progress;
  }

  updateJourneyProgress<T extends JourneyType>(
    journeyType: T,
    stepKey: JourneySteps<T>,
    metadata?: Record<string, unknown>
  ) {
    if (this.hasCompletedStep(journeyType, stepKey)) {
      devLog("Already completed", journeyType, stepKey);
      return;
    }

    const journey = Object.values(UserJourneys).find(
      (j) => j.Type === journeyType
    ) as JourneyConfig<T>;

    if (!journey) {
      console.error(`Invalid journey type: ${journeyType}`);
      return;
    }

    // Convert tuple to array for includes check
    const steps = [...journey.Steps];
    if (!steps.includes(stepKey)) {
      console.error(`Invalid step ${stepKey} for journey ${journeyType}`);
      return;
    }

    const progressSetter = this.setters.get("journeyProgress");
    if (progressSetter) {
      progressSetter({ journeyType, stepKey });
    }

    trackEvent({
      type: "journey",
      category: journeyType,
      action: stepKey,
      metadata,
      flush: true,
    });
  }

  private hasCompletedStep<T extends JourneyType>(
    journeyType: T,
    stepKey: JourneySteps<T>
  ) {
    return this.currentProgress[journeyType]?.has(stepKey);
  }

  reset() {
    this.setters.clear();
  }
}

export const journeyManager = JourneyManager.getInstance();
