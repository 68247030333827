//import Hotjar from "@hotjar/browser";
import * as Sentry from "@sentry/react";
import { callGtag } from "../../services/ga";
import { queueEvent } from "./analyticsBatcher";

export const GA_TRACKING_ID = "G-893ZGSLH00";
export const GA_DEBUG_MODE = false;

type AssetType = "catalogs" | "products" | "space" | "users";

export type EventRecord = {
  type?: "feature" | "conversion" | "journey" | "session" | "error" | "trace";
  category: string;
  action: string;
  value?: number;
  label?: string;
  ts?: string;
  feature?: boolean;
  internal?: boolean;
  tag?: boolean;
  timestamp?: string;
  sessionId?: string;
  assets?: AssetType[];
  metadata?: Record<string, unknown>;
  flush?: boolean;
};

export const trackEvent = (event: EventRecord) => {
  if (!event.internal) {
    callGtag("event", event.category + "_" + event.action, {
      value: event.value ?? 1,
      debug_mode: GA_DEBUG_MODE,
    });
    Sentry.addBreadcrumb({
      category: event.category + "_" + event.action,
      message: `${event.value > 1 ? "[" + event.value + "] " : ""}${
        event.label
      }`,
      level: "info",
    });
  }
  if (event.tag || event.feature) {
    Sentry.setTag("feature." + event.category, event.action);
  }
  if (event.feature) {
    Intercom("trackEvent", event.action + " " + event.category, {
      label: event.label,
      value: event.value ?? 1,
    });
    //Hotjar.event("event:" + event.category + "_" + event.action);
  }
  queueEvent(event);
};
