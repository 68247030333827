import { trackEvent } from "../analytics/analytics";
import apiClient, {
  BaseOperationResult,
  OperationResult,
} from "../../services/apiClient";
import { logError } from "../../services/logging";
import { EMPTY_GUID } from "../data/data-types";
import { formatDateObj } from "../data/time";
import { CatalogBuilder } from "./catalog.builder";
import { HubCatalog, SiteHubData } from "./catalog.hub";
import { ProductContainerFields } from "./catalog.products";
import { CatalogRef, Catalog, mapCatalog, CatalogItemRef } from "./catalogs";
import { journeyManager } from "../guide/journeyManager";

export const loadCatalogsDb = async (
  storageUrl: string
): Promise<CatalogRef[]> => {
  const response = await apiClient.get<Catalog[]>("/catalogs/list");
  return response.data.map((catalog) => mapCatalog(catalog, storageUrl));
};

export const loadCatalog = async (catalogId: string): Promise<Catalog> => {
  const response = await apiClient.get<Catalog>(
    "/catalogs/get?id=" + catalogId
  );
  return response.data;
};

export type CatalogBackup = {
  id: string;
  isManual: boolean;
  name: string;
};

export type CatalogSaveResult = OperationResult<{
  catalog: Catalog;
}>;

export const saveCatalog = async (
  catalog: Catalog,
  backupType?: string
): Promise<CatalogSaveResult> => {
  const isNew = catalog.id === EMPTY_GUID;
  trackEvent({
    category: "catalog",
    action: isNew ? "create" : "update",
    label: (isNew ? "new" : catalog.id) + " : " + catalog.name,
    feature: true,
    assets: isNew ? ["catalogs"] : undefined,
  });

  const response = await apiClient.post<CatalogSaveResult>("/catalogs/save", {
    catalog,
    backupType,
    backupName: formatDateObj(new Date(), true, "long"),
  });
  return response.data;
};

export const deleteCatalog = async (
  catalogId: string
): Promise<OperationResult<string>> => {
  trackEvent({ category: "catalog", action: "delete", label: catalogId });
  const response = await apiClient.post<OperationResult<string>>(
    "/catalogs/delete-catalog?catalogId=" + catalogId
  );
  return response.data;
};

export const copyCatalog = async (
  catalogId: string
): Promise<OperationResult<Catalog>> => {
  trackEvent({ category: "catalog", action: "copy", label: catalogId });
  const response = await apiClient.post<OperationResult<Catalog>>(
    "/catalogs/copy-catalog?catalogId=" + catalogId
  );
  return response.data;
};

export const loadCatalogBackups = async (
  catalogId: string
): Promise<CatalogBackup[]> => {
  const response = await apiClient.get<CatalogBackup[]>(
    "/catalogs/backups?catalogId=" + catalogId
  );
  return response.data;
};

export const restoreCatalogBackup = async (
  catalogId: string,
  backupId: string
): Promise<OperationResult<Catalog>> => {
  trackEvent({ category: "catalog", action: "restore", label: catalogId });
  const response = await apiClient.post<OperationResult<Catalog>>(
    "/catalogs/restore-backup?catalogId=" + catalogId + "&backupId=" + backupId
  );
  return response.data;
};

const createCatalogPages = async (
  builder: CatalogBuilder,
  pageItem: CatalogItemRef
) => {
  try {
    const subcatalog = pageItem.info.subcatalog;
    const cr = builder.data.catalogs.find(
      (c) => c.catalog.id === subcatalog.catalog.id
    );
    if (!cr) return;

    cr.catalog = await loadCatalog(subcatalog.catalog.id);

    const b = new CatalogBuilder(subcatalog.catalog);
    b.setData(builder.data);

    const masterConfig = pageItem.info.config;

    subcatalog.pages = b.pages
      .filter((p) => p.pageItem?.di.PageType !== "toc")
      .map((p) => {
        const page = {
          ...p,
          readonly: true,
          pageId: pageItem.di.PageId,
          info: {
            ...p.pageItem.info,
            config: { ...masterConfig, ...p.pageItem.info.config },
          },
        };
        //updatePage(builder, page);
        return page;
      });
  } catch (e) {
    console.error("subcatalog load", e);
    logError(e);
  }
};
export const loadSiteHub = async (): Promise<SiteHubData> => {
  const response = await apiClient.get<SiteHubData>("/catalogs/kits");
  return response.data;
};

export const importCatalogKit = async (
  catalog: HubCatalog,
  fields: ProductContainerFields,
  withProducts: boolean
): Promise<OperationResult<Catalog>> => {
  trackEvent({
    category: "catalog",
    action: "import",
    label: catalog.catalogId + " : " + catalog.hubEntity.link,
    feature: true,
  });
  const response = await apiClient.post<OperationResult<Catalog>>(
    "/catalogs/import-kit",
    {
      catalogId: catalog.catalogId,
      withProducts,
      ...fields,
    }
  );
  journeyManager.updateJourneyProgress("catalog_creation", "catalog_template", {
    catalog: catalog.title,
  });
  return response.data;
};

export const prepareCatalogKit = async (
  catalog: HubCatalog
): Promise<BaseOperationResult> => {
  trackEvent({
    category: "catalog",
    action: "prepare-kit",
    label: catalog.catalogId + " : " + catalog.hubEntity.link,
  });
  const response = await apiClient.post<OperationResult<Catalog>>(
    "/catalogs/prepare-kit?catalogId=" + catalog.catalogId
  );
  return response.data;
};

export const loadStructureData = async (
  builder: CatalogBuilder,
  initial: boolean
) => {
  const forLoadSubcatalogs = builder.pages
    .map((p) => p.pageItem)
    .filter(
      (s) => s?.info.subcatalog && (initial || !s?.info.subcatalog.pages)
    );

  if (!forLoadSubcatalogs.length) return false;

  const promises = forLoadSubcatalogs.map(async (s) => {
    await createCatalogPages(builder, s);
  });

  await Promise.all(promises);

  builder.refreshStructure();
  return true;
};
