export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export const getUnique = <T>(arr: T[], key: (e) => string) => {
  const unique = arr
    .map((e) => key(e))
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter((i) => arr[i])
    .map((i) => arr[i]);
  return unique;
};

export const randomNum = (min = 0, max = 10000) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const devLog = (...args: unknown[]) => {
  if (process.env.NODE_ENV?.toLowerCase() === "development") {
    console.log(...args);
  }
};
