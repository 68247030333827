import apiClient from "../../services/apiClient";
import { devLog } from "../../services/util";
import { getDecodedToken, isLoggedIn } from "../accounts/auth.service";
import { EventRecord } from "./analytics";
import { v4 as uuidv4 } from "uuid";

class AnalyticsBatcher {
  private eventQueue: EventRecord[] = [];
  readonly sessionId: string;
  private flushTimeout?: number;
  private readonly FLUSH_INTERVAL_MS = 300000;
  private readonly BATCH_SIZE = 50;

  constructor() {
    const token = getDecodedToken();
    this.sessionId = token?.sessionId || generateFallbackSessionId();
  }

  queueEvent(event: Omit<EventRecord, "sessionId" | "ts">) {
    if (!event.type) {
      if (event.feature || event.tag) event.type = "feature";
      else if (!event.internal) event.type = "trace";
    }

    if (event.type === "feature" && event.value === undefined) event.value = 1;

    this.eventQueue.push({
      ...event,
      sessionId: this.sessionId,
      ts: new Date().toLocaleTimeString(),
      timestamp: new Date().toISOString(),
    });

    // Flush if queue is full
    if (this.eventQueue.length >= this.BATCH_SIZE || event.flush) {
      this.flush(event.flush ? event.category : "batch-full");
      return;
    }

    // Start timeout if not already started
    this.startFlushTimeout();
  }

  private startFlushTimeout() {
    if (!this.flushTimeout) {
      this.flushTimeout = window.setTimeout(() => {
        this.flush("timeout");
      }, this.FLUSH_INTERVAL_MS);
    }
  }

  async flush(reason: string) {
    if (this.eventQueue.length === 0 || !isLoggedIn()) return;

    const events = [...this.eventQueue];
    try {
      this.eventQueue = [];

      // Clear any pending timeout
      if (this.flushTimeout) {
        window.clearTimeout(this.flushTimeout);
        this.flushTimeout = undefined;
      }

      await apiClient.post("/admin/events", {
        host: window.location.host,
        sessionId: this.sessionId,
        events,
        reason,
      });

      // Start new timeout for any new events
      this.startFlushTimeout();
    } catch (error) {
      console.error("Failed to send analytics batch:", error);
      // On error, put events back in queue
      this.eventQueue.unshift(...events);
      // // Retry after delay
      // setTimeout(() => this.flush("retry"), 5000);
    }
  }

  // Make flush accessible to LogProvider
  static getInstance() {
    if (!this.instance) {
      this.instance = new AnalyticsBatcher();
    }
    return this.instance;
  }

  private static instance: AnalyticsBatcher;
}

export const queueEvent = (event: Omit<EventRecord, "sessionId" | "ts">) => {
  const analyticsBatcher = AnalyticsBatcher.getInstance();
  analyticsBatcher.queueEvent(event);
  devLog("trackEvent", event);
};

export const saveEventLog = (reason: string) => {
  const analyticsBatcher = AnalyticsBatcher.getInstance();
  analyticsBatcher.flush(reason);
};

export const getSessionId = () => {
  return AnalyticsBatcher.getInstance().sessionId;
};

function generateFallbackSessionId(): string {
  const now = new Date();
  const datePart = now
    .toISOString()
    .slice(0, 13) // Get yyyy-MM-ddTHH
    .replace(/[-T]/g, ""); // Convert to yyyyMMddHH
  const uuid = uuidv4().replace(/-/g, "");

  return `${datePart}-${uuid}`;
}
