import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { accountHub } from "../../services/accountHub";
import { devLog } from "../../services/util";
import { useAtom, useSetAtom } from "jotai";
import { sessionAtom } from "../../features/accounts/account.state";
import { journeyManager } from "../../features/guide/journeyManager";
import { addJourneyProgressAtom } from "../../features/guide/journey.state";
import { useQuickGuide } from "../../features/guide/useQuickGuide";
import { pageGuideConfigs } from "../../features/guide/journey";
import { logError } from "../../services/logging";

export const JourneyProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const addProgress = useSetAtom(addJourneyProgressAtom);
  const {
    guideState,
    updateGuideDisplay,
    journeyProgress,
    loadJourneyProgress,
  } = useQuickGuide();
  const [session] = useAtom(sessionAtom);

  useEffect(() => {
    if (session?.account) {
      journeyManager.setProgress(journeyProgress);
    }
  }, [journeyProgress, session?.account]);

  // Register journey progress setter
  useEffect(() => {
    if (session?.account) {
      journeyManager.registerSetter("journeyProgress", addProgress);
      return () => {
        journeyManager.unregisterSetter("journeyProgress");
      };
    }
  }, [addProgress, session?.account]);

  useEffect(() => {
    if (!session?.account) return;

    let mounted = true;
    let cleanup: (() => void) | undefined;

    const initialize = async () => {
      try {
        await loadJourneyProgress();
        await accountHub.connect();

        if (mounted) {
          //setIsConnected(true);
          cleanup = accountHub.onJourneyUpdated((data) => {
            addProgress(data);
            devLog("Journey update in provider:", data);
          });
        }
      } catch (error) {
        console.error("Failed to initialize journey provider:", error);
        logError(error);
      }
    };

    initialize();

    return () => {
      mounted = false;
      if (cleanup) cleanup();
      accountHub.disconnect();
    };
  }, [addProgress, loadJourneyProgress, session?.account]);

  // Guide behavior effect
  useEffect(() => {
    if (!session?.account) return;
    if (guideState.userMode == "hidden") return;

    const currentConfig = pageGuideConfigs.find((config) =>
      config.paths.some((path) => location.pathname.startsWith(path))
    );

    if (guideState.userMode == "expanded" || currentConfig?.mode == "hidden") {
      updateGuideDisplay(currentConfig?.mode ?? "expanded");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, session?.account]);

  return <>{children}</>;
};
