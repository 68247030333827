import { useCallback, useState } from "react";
import {
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import {
  useChainedCommands,
  useActive,
  useCurrentSelection,
  useMarkRange,
  useRemirrorContext,
} from "@remirror/react";
import {
  Fragment,
  findParentNodeOfType,
  getTextContentFromSlice,
} from "remirror";
import {
  StyledLinkExtension,
  styledLinkName,
} from "../extensions/link-extension";
import { CommandButton } from "@remirror/react-ui";

const isText = (f: Fragment) => {
  let result = true;
  f.forEach((node) => {
    result &&= node.isText || node.isTextblock;
  });
  return result;
};

type TargetType = "_blank" | "";

export const LinkButton = () => {
  const chain = useChainedCommands<StyledLinkExtension>();
  const active = useActive().styledLink();
  const selection = useCurrentSelection();

  const [isEditing, setIsEditing] = useState(false);
  const [isValid, setIsValid] = useState(false);

  //const url = (useAttrs().styledLink()?.href as string) ?? "";
  const [href, setHref] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [target, setTarget] = useState<TargetType>("_blank");

  const range = useMarkRange("link");

  const { getState } = useRemirrorContext();

  // useEffect(() => {
  //   setHref(url);
  // }, [url]);

  const validateLink = useCallback((url: string, soft = false) => {
    const startsWithProtocol =
      /^((?:https?|ftp)?:)\/\//.test(url) ||
      (url.startsWith("mailto:") && url.includes("@"));
    if (!soft || startsWithProtocol) setIsValid(startsWithProtocol);
  }, []);

  const clickEdit = useCallback(() => {
    const { from, to } = range ?? selection;
    const doc = getState().doc;
    const slice = doc.slice(from, to);
    if (isText(slice.content)) {
      const text = getTextContentFromSlice(slice);
      setTitle(text);
    } else {
      setTitle(null);
    }
    if (selection.empty) {
      const parent = findParentNodeOfType({
        types: styledLinkName,
        selection: selection,
      });
      if (parent) {
        const h = parent.node.attrs.href;
        validateLink(h);
        setHref(h);
        setTarget(parent.node.attrs.target);
        setTitle(parent.node.textContent);
      }
      chain.selectStyledLink().run();
    }
    setIsEditing(true);
    //validateLink(href);
  }, [chain, getState, range, selection, validateLink]);

  const removeLink = useCallback(() => {
    chain.removeStyledLink().focus().run();
    setIsEditing(false);
  }, [chain]);

  const save = useCallback(() => {
    setIsEditing(false);

    if (href === "") {
      chain.removeStyledLink();
    } else {
      const { from, to } = range ?? selection;
      const node = getState().doc.nodeAt(from);

      if (node?.type.name === styledLinkName) {
        chain.updateStyledLink({ href, target }, title);
      } else if (title) {
        chain.replaceText({
          content: title,
          range,
          selection: range ? null : selection,
          type: styledLinkName,
          attrs: { href, target },
        });
      }
      const end = title ? from + title.length : to;
      chain.focus(end);
    }
    chain.run();
  }, [href, chain, title, range, selection, getState, target]);

  const enabled = active || !selection.empty;

  return (
    <>
      <CommandButton
        commandName="insertLink"
        label={enabled ? (active ? "Edit link" : "Insert link") : null}
        icon="link"
        enabled={enabled}
        active={active}
        onSelect={clickEdit}
      />
      <Dialog
        open={isEditing}
        onClose={() => setIsEditing(false)}
        maxWidth="md"
      >
        <DialogContent>
          {title !== null && (
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Link Title"
              fullWidth
              variant="standard"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          )}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Link Address"
            fullWidth
            variant="standard"
            value={href}
            onChange={(e) => {
              setHref(e.target.value);
              validateLink(e.target.value, true);
            }}
            onBlur={() => validateLink(href)}
            error={!isValid}
            helperText={!isValid && "Invalid address"}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={target === "_blank"}
                onChange={(e) => {
                  setTarget(e.target.checked ? "_blank" : "");
                }}
                name="openInNewTab"
                color="primary"
              />
            }
            label="Open in new tab"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={removeLink} color="error" sx={{ mr: "auto" }}>
            Remove
          </Button>
          <Button onClick={() => setIsEditing(false)}>Cancel</Button>
          <Button variant="outlined" color="primary" onClick={save}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
