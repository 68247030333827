import { SvgIconComponent } from "@mui/icons-material";
import { IconTypes } from "../../components/icons.types";
import { JourneyType, JourneySteps } from "./journey";
import { Permission } from "../accounts/account";
import { FeatureKey } from "./features";

export interface BaseStep<T extends JourneyType = JourneyType> {
  key: string;
  label?: string;
  description?: string;
  icon?: SvgIconComponent;
  journeyType?: T;
  completionStep?: JourneySteps<T>;
  permission?: Permission;
  completed?: boolean;
  feature?: FeatureKey;
}

export interface Substep extends BaseStep {
  path?: string;
  requiresCatalog?: boolean;
  requiresProducts?: boolean;
}

export interface GuideStep extends BaseStep {
  tips?: string[];
  substeps: Substep[];
}

export const guideSteps: GuideStep[] = [
  {
    key: "products",
    label: "Add Products",
    icon: IconTypes.Product,

    journeyType: "onboarding",
    completionStep: "first_product",
    permission: "products",
    description: "Import or create your products",
    tips: [
      "Choose the fastest way to add your products - import or create manually",
      "You can always update products and add more later",
      "Start with a few products to learn the system",
    ],
    substeps: [
      {
        key: "import_shopify",
        feature: "shopify",
        description: "Connect your store",
      },
      {
        key: "import_csv",
        feature: "csv",
        label: "Upload CSV",
        description: "Import from spreadsheet",
      },
      {
        key: "add_manual",
        feature: "products",
        label: "Add Manually",
        description: "Create manually one by one",
      },
    ],
  },
  {
    key: "catalog",
    label: "Create Catalog",
    icon: IconTypes.Catalog,
    journeyType: "onboarding",
    completionStep: "first_catalog",
    permission: "catalogs",
    description: "Start your first product catalog",
    tips: [
      "Templates help you create professional catalogs quickly",
      "You can customize every aspect of your catalog later",
    ],
    substeps: [
      {
        key: "new_catalog",
        label: "Blank Catalog",
        description: "Start from scratch",
        path: "/catalogs/create",
        feature: "catalogs",
      },
      {
        key: "use_template",
        label: "Use Template",
        description: "Start with a design",
        feature: "catalogTemplates",
      },
    ],
  },
  {
    key: "quick_design",
    label: "Quick Catalog",
    icon: IconTypes.Awesome,
    permission: "catalogs",
    description: "Create a professional catalog in minutes",
    tips: [
      "<b>Auto Grid</b> instantly creates multi-product pages",
      "<b>Product Lists</b> are perfect for price lists and indexes",
      "These tools help you create catalogs 10x faster",
    ],
    substeps: [
      {
        key: "grid",
        feature: "autoGrid",
        requiresCatalog: true,
      },
      {
        key: "list",
        feature: "productList",
        requiresCatalog: true,
      },
    ],
  },

  {
    key: "publish",
    label: "Share Catalog",
    icon: IconTypes.Share,
    permission: "catalogs",
    description: "Share your catalog with customers",
    tips: [
      "Preview ensures everything looks perfect",
      "Share online for interactive browsing",
      "Download PDF for printing or offline sharing",
    ],
    substeps: [
      {
        key: "preview",
        label: "Preview Online",
        description: "Review your catalog",
        icon: IconTypes.OpenInNew,
        requiresCatalog: true,
        journeyType: "catalog_creation",
        completionStep: "preview",
      },
      {
        key: "publish",
        label: "Publish Catalog",
        description: "Make it public",
        icon: IconTypes.Publish,
        requiresCatalog: true,
        journeyType: "onboarding",
        completionStep: "catalog_published",
      },
      {
        key: "download",
        label: "Download PDF",
        description: "Get a print-ready version",
        icon: IconTypes.PDF,
        requiresCatalog: true,
        journeyType: "catalog_creation",
        completionStep: "download_pdf",
      },
      {
        key: "share",
        label: "Share Online",
        description: "Share with customers",
        icon: IconTypes.Share,
        requiresCatalog: true,
        journeyType: "catalog_creation",
        completionStep: "share",
      },
    ],
  },
  {
    key: "product_setup",
    label: "Organize Products",
    icon: IconTypes.Category,
    permission: "products",
    description: "Structure your product data for efficient catalog creation",
    tips: [
      "Use <b>Categories</b> to define product properties (size, color, etc)",
      "Create <b>Collections</b> to group products for different catalogs",
      "Add <b>Custom fields</b> for unique product information",
      "Set up <b>Variants</b> for products with options",
      "Manage multiple product images efficiently",
    ],
    substeps: [
      {
        key: "categories",
        feature: "categories",
        description: "Add custom product fields and options",
      },
      {
        key: "collections",
        feature: "collections",
        description: "Organize your products",
      },
      {
        key: "variants",
        label: "Product Variants",
        description: "Manage product options",
        icon: IconTypes.Tune,
        journeyType: "product_setup",
        completionStep: "add_variants",
        requiresProducts: true,
        feature: "products",
      },
    ],
  },
  {
    key: "advanced_design",
    label: "Advanced Design",
    icon: IconTypes.Design,
    permission: "catalogs",
    description: "Create custom catalog designs",
    tips: [
      "Use <b>Product Cards</b> to create unique product displays",
      "Design <b>Page Layouts</b> to match your brand",
      "Save your designs to reuse in other catalogs",
    ],
    substeps: [
      {
        key: "card",
        feature: "productCard",
        requiresCatalog: true,
      },
      {
        key: "layout",
        feature: "layout",
        requiresCatalog: true,
      },
      {
        key: "design_layout",
        label: "Design Layout",
        description: "Build your page layouts",
        icon: IconTypes.Layout,
        path: "/design/layouts",
        journeyType: "catalog_creation",
        completionStep: "design_layout",
        permission: "catalogs",
      },
    ],
  },
  {
    key: "advanced",
    label: "Power Features",
    icon: IconTypes.Dashboard,
    description: "Unlock more catalog possibilities",
    tips: [
      "Centralize image management for products and catalogs",
      "Use parameters for dynamic catalog content",
      "Collaborate with your team efficiently",
    ],
    substeps: [
      {
        key: "manage_images",
        feature: "images",
      },
      {
        key: "use_parameters",
        label: "Use Parameters",
        description: "Add dynamic content",
        icon: IconTypes.Tune,
        path: "/parameters",
        journeyType: "discovery",
        completionStep: "use_parameters",
      },
      {
        key: "add_user",
        label: "Add User",
        description: "Invite team members",
        icon: IconTypes.Users,
        path: "/account/users",
        journeyType: "discovery",
        completionStep: "add_user",
        permission: "admin",
      },
      {
        key: "settings",
        label: "Account Settings",
        description: "Manage your account",
        icon: IconTypes.Settings,
        path: "/account",
      },
      {
        key: "support",
        label: "Get Support",
        description: "Contact our team",
        icon: IconTypes.Chat,
      },
    ],
  },
];
