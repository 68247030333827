import { useCallback, useEffect, useRef } from "react";
import { HtmlEditorRef } from "../../../components/remirror/editor-func";
import {
  clipboardAtom,
  getClipboardItem,
} from "../../../features/design/clipboard";
import {
  DesignItemRef,
  getPositonAttr,
  DesignItemType,
  DesignSubtype,
} from "../../../features/design/design.types";
import {
  DesignBoardActionsProps,
  ItemSelectionMode,
} from "../designer.board.api";
import { onBoardDropGlobalHandle } from "../designer.func";
import { useAtom } from "jotai";
import { useDialogs } from "../../../contexts/useDialogs";

type BoardActionsProps = {
  editorRef: React.MutableRefObject<HtmlEditorRef>;
  boardRef: React.MutableRefObject<HTMLDivElement>;
  pageZoom: number;
} & DesignBoardActionsProps;

export const useBoardActions = ({
  designerBoardApi: api,
  pageItems,
  boardContainer,
  onChange,
  editorRef,
  boardRef,
  onItemDrop,
  readOnly,
  pageZoom,
}: BoardActionsProps) => {
  const { selectItem, selectedItem, editedHtml, targets, setTargets } = api;

  const [clipboard] = useAtom(clipboardAtom);
  const dragCounter = useRef(0);
  const dropTarget = useRef<HTMLElement>(null);

  const { isHtmlEditorOpened } = useDialogs();

  const selectBoardItem = useCallback(
    (item: DesignItemRef, mode: ItemSelectionMode) => {
      selectItem(item.id, false, false, mode);
      setTimeout(() => {
        const el = document.querySelector(
          boardContainer + " .board-item[id='" + item.id + "']"
        ) as HTMLElement;
        if (el) setTargets([el]);
      });
    },
    [boardContainer, selectItem, setTargets]
  );

  const selectNextItem = useCallback(() => {
    setTargets([]);
    const attr = getPositonAttr(api.meta);
    const pos = selectedItem?.di[attr] ?? 0;
    const items = pageItems
      .filter((i) => i.type !== "page")
      .sortList((e) => e.di[attr] ?? 0);
    const i =
      items.find(
        (i) =>
          i.di[attr] > pos || (i.di[attr] === pos && i.id > selectedItem?.id)
      ) ?? items[0];
    if (i) {
      selectBoardItem(i, "click");
    }
  }, [
    api.meta,
    pageItems,
    selectBoardItem,
    selectedItem?.di,
    selectedItem?.id,
    setTargets,
  ]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      // const insideTextEditor =
      //   event.target instanceof HTMLElement &&
      //   event.target.closest(".remirror-editor") !== null;

      if (editedHtml == null && !isHtmlEditorOpened()) {
        if (selectedItem) {
          if (event.key === "Enter") {
            editorRef.current?.focus();
            selectItem(selectedItem.id);
          }
        }
        if (targets.length > 0) {
          if (event.key === "Delete") {
            api.deleteItems();
          }
          if (event.ctrlKey && event.key?.toLocaleLowerCase() === "c") {
            api.copyItems();
          }
        }
        if (event.ctrlKey && event.key?.toLocaleLowerCase() === "z") {
          onChange({ type: event.shiftKey ? "redo" : "undo", index: 0 });
        }

        if (event.ctrlKey && event.key?.toLocaleLowerCase() === "x") {
          api.copyItems();
          api.deleteItems();
        }

        if (event.ctrlKey && event.key?.toLocaleLowerCase() === "v") {
          api.pasteItem();
        }
      }

      if (targets.length > 0) {
        if (event.key === "Escape") {
          setTargets([]);
          selectItem();
        }
      }
      if (event.key === "Tab") {
        event.preventDefault();
        selectNextItem();
      }
    },
    [
      api,
      editedHtml,
      editorRef,
      isHtmlEditorOpened,
      onChange,
      selectItem,
      selectNextItem,
      selectedItem,
      setTargets,
      targets.length,
    ]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const dragOverElement = useCallback((event) => {
    event.preventDefault();
  }, []);

  const dragOverToggle = useCallback(
    (event: React.DragEvent<HTMLDivElement>, over: boolean) => {
      if (readOnly) return;

      const el = event.target as HTMLElement;
      const target = el.closest(
        boardContainer + " .element-drop-target"
      ) as HTMLElement;

      dragCounter.current += over ? 1 : -1;

      if (dragCounter.current === 0) {
        target.classList.remove("drag-over");
        dropTarget.current = null;
        return;
      }

      if (target.id === dropTarget.current?.id) return;

      if (over) {
        dropTarget.current?.classList.remove("drag-over");
        dropTarget.current = target;
        target.classList.add("drag-over");
      }
    },
    [boardContainer, readOnly]
  );

  const selectDrop = useCallback(
    (item: DesignItemRef, action: string) => {
      selectBoardItem(item, "drop");
      onChange({ type: "refresh", action });
    },
    [onChange, selectBoardItem]
  );

  const dropElement = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      if (readOnly) return;

      event.preventDefault();

      const type = event.dataTransfer.getData("type") as DesignItemType;
      const contentType = event.dataTransfer.getData(
        "contentType"
      ) as DesignItemType;
      const subtype = event.dataTransfer.getData("subtype") as DesignSubtype;
      const content = event.dataTransfer.getData("content");
      const productId = event.dataTransfer.getData("productId");
      const templateId = event.dataTransfer.getData("templateId");
      const clipboardIndex = event.dataTransfer.getData("clipboardIndex");

      const el = event.target as HTMLElement;
      const target = el.closest(
        boardContainer + " .element-drop-target"
      ) as HTMLElement;

      const targetRect = boardRef.current.getBoundingClientRect();

      dropTarget.current?.classList.remove("drag-over");
      dropTarget.current = null;
      dragCounter.current = 0;

      if (target.classList.contains("drop-container") || clipboardIndex) {
        const item = onItemDrop({
          isNew: true,
          type: type === "product" ? contentType : type,
          subtype,
          content,
          x: (event.clientX - targetRect.left) / pageZoom,
          y: (event.clientY - targetRect.top) / pageZoom,
          productId,
          templateId,
          clone: clipboardIndex
            ? getClipboardItem(clipboard, api.meta, +clipboardIndex)
            : null,
        });
        const desc = type === "product-image" ? "product image" : type;
        selectDrop(item, "add " + desc);
      } else {
        const item = api.getItemById(target.id);
        const metaType = type === "product-image" ? "image" : type;
        if (item?.type === metaType || type === "product") {
          onItemDrop({
            isNew: false,
            item,
            type,
            content: type === "product" ? item.content : content,
            productId,
            templateId,
          });
          selectItem(item.id);
          selectDrop(item, "replace " + type);
        }
      }
      onBoardDropGlobalHandle?.();
    },
    [
      boardContainer,
      boardRef,
      clipboard,
      api,
      onItemDrop,
      pageZoom,
      readOnly,
      selectDrop,
      selectItem,
    ]
  );

  return {
    selectBoardItem,
    dropElement,
    dragOverToggle,
    dragOverElement,
  };
};
