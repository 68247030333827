import { Suspense } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { SnackbarProvider } from "notistack";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { createAppRoutes } from "./AppRoutes";

import ErrorBoundary from "./contexts/ErrorBoundary";
import AppGlobalStyles from "./components/AppGlobalStyles";
import { appTheme } from "./ui/theme";
import { LogProvider } from "./contexts/log.provider";
import { initLogging } from "./services/logging";
import LoadingScreen from "./components/LoadingScreen";

import "./ui/css/general.scss";
import { LicenseInfo } from "@mui/x-license";

initLogging();
LicenseInfo.setLicenseKey(
  "83a0ce016c840073dc62a42cd762cb83Tz05OTU5MSxFPTE3NjAwMjQzMzEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg=="
);

const router = createBrowserRouter(createAppRoutes());

const App = () => {
  //const theme = createAppTheme();

  return (
    <ThemeProvider theme={appTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider dense maxSnack={3}>
          <ErrorBoundary>
            <LogProvider>
              <Suspense fallback={<LoadingScreen title="loading app" />}>
                <CssBaseline />
                <AppGlobalStyles />
                <RouterProvider router={router} />
              </Suspense>
            </LogProvider>
          </ErrorBoundary>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
